import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  survey: {
    cid: null,
    oid: null,
    followup_consent: false,
    q1: "",
    q1a: "",
    q2: "",
    q2a: "",
    q3: "",
    q4: "",
    q5: "",
    q6: "",
    q7: "",
    q7a: "",
    q8: "",
    q8_other: "",
    q9: ""
  },
};

export const surveySlice = createSlice({
  name: "survey",
  initialState,
  reducers: {
    setAnswer: (state, action) => {
      state.survey[action.payload.name] = action.payload.value;
      if(action.payload.cid) {
        state.survey.cid = action.payload.cid;
      }
      if(action.payload.oid) {
        state.survey.oid = action.payload.oid;
      }
    },
  }
});

// Action creators are generated for each case reducer function
export const {
  setAnswer,
} = surveySlice.actions;

export default surveySlice.reducer;
