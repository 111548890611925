import { createSlice } from "@reduxjs/toolkit";
import { setCa } from "store/reducers/order/orderSlice";
const initialState = {
  order: {
    subTotal: 0,
    hst: 0,
    gst: 0,
    pst: 0,
    qst: 0,
    total: 0,
    currentStep: 0,
    installationDate: null,
    installationTime: null,
    installationTime2: null,
    nextInstallationDate: null,
    nextInstallationTime: null,
    unavailableDates: [],
    hasSelectedInstallationTime: false,
    preferredSooner: false,
    preferredSoonerComment: null,
    autopayCredit: 0,
    internet: {
      selected: "",
      title: "",
      price: 0,
      cpe_router: null,
      wifi_ap: null,
    },
    tv: {
      price: 0,
      id: null,
      title: "",
      topBoxes: {
        id: null,
        selected: 0,
        price: 0,
      },
      themePacks: {
        selected: [],
        price: 0,
      },
      channels: {
        selected: [],
        price: 0,
      },
    },
    homePhone: {
      id: null,
      price: 0,
    },
  },
  userInfo: {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    address_id: 0,
    address: "",
    unit: "",
    province: "",
    unitHasService: false,
    emailVerificationComplete: false,
    emailStep: 1,
    isPreorder: false,
    onNetDate: null,
    preOrderFirstBookingDate: null,
  },
  card: {
    dataKey: null,
    cardAdded: false,
    sameBillingAddress: true,
    cardType: "",
    billingAddress: "",
    billingAddress2: "",
    billingCity: "",
    billingPostal: "",
    billingProvince: "",
  },
};

const calculateTotal = (state) => {
  const internetTotal = state.order.internet.price - Math.abs(Number(state.order.autopayCredit));
  state.order.subTotal = Number(
      (
        internetTotal +
        state.order.tv.price +
        state.order.homePhone.price +
        state.order.tv.channels.price +
        state.order.tv.topBoxes.price +
        state.order.tv.themePacks.price
      ).toFixed(2)
  );
  state.order.hst = Number((state.order.subTotal * 0.13).toFixed(2));
  state.order.pst = Number((state.order.subTotal * 0.07).toFixed(2));
  state.order.gst = Number((state.order.subTotal * 0.05).toFixed(2));
  state.order.qst = Number((state.order.subTotal * 0.09975).toFixed(2));
  if (state.userInfo.province === "BC") {
    state.order.total = Number(
      (
        state.order.subTotal +
        state.order.pst +
        state.order.gst
      ).toFixed(2)
    );
  } else if (state.userInfo.province === "QC") {
    state.order.total = Number(
      (
        state.order.subTotal +
        state.order.qst +
        state.order.gst
      ).toFixed(2)
    );
  } else {
    state.order.total = Number(
      (
        state.order.subTotal +
        state.order.hst
      ).toFixed(2)
    );
  }
};

export const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    stepPlus: (state) => {
      if (state.order.currentStep < 4)
        state.order.currentStep = state.order.currentStep + 1;
    },
    stepMinus: (state) => {
      if (state.order.currentStep > 0)
        state.order.currentStep = state.order.currentStep - 1;
    },
    setStep: (state, action) => {
      state.order.currentStep = action.payload;
    },
    setInternet: (state, action) => {
      for (let [key, value] of Object.entries(action.payload)) {
        state.order.internet[key] = value;
      }
      //PRICE CALCULATION FOR INTERNET
      calculateTotal(state);
    },
    setInternetPrice: (state, action) => {
      state.order.internet.price = action.payload;
    },
    setTv: (state, action) => {
      for (let [key, value] of Object.entries(action.payload)) {
        state.order.tv[key] = value;
      }
      //RESET TV SECTION WHEN MAIN TV PRICE IS 0 MEANING NO TV IS SELECTED
      if (action.payload.price === 0) {
        state.order.tv = initialState.order.tv;
      }
      //PRICE CALCULATION FOR TV
      calculateTotal(state);
    },
    setTopBoxes: (state, action) => {
      if (action.payload.action === "add") {
        state.order.tv.topBoxes.selected += 1;
        state.order.tv.topBoxes.id = action.payload.id;
      } else {
        state.order.tv.topBoxes.selected -= 1;
      }
      state.order.tv.topBoxes.price =
        state.order.tv.topBoxes.selected * action.payload.price;
      if (state.order.tv.price === 0 && state.order.tv.topBoxes.selected > 0) {
        state.order.tv.price = 20;
        state.order.tv.starter = true;
      }
      //PRICE CALCULATION FOR TV
      calculateTotal(state);
    },
    setTvChannels: (state, action) => {
      if (!action.payload.value.price) action.payload.value.price = 3;
      if (action.payload.action === "add")
        state.order.tv.channels.selected.push(action.payload.value);
      else
        state.order.tv.channels.selected =
          state.order.tv.channels.selected.filter(
            (channel) => channel.channel_id !== action.payload.value.channel_id
          );

      //IF TV IS NOT SELECTED BUT CHANNEL IS, TV IS SET TO SRATER
      // if (
      //   state.order.tv.price === 0 &&
      //   state.order.tv.channels.selected.length > 0
      // ) {
      //   state.order.tv.price = 20;
      //   state.order.tv.starter = true;
      // }
      // PRICE CALCULATION FOR TV CHANNELS
      state.order.tv.channels.price =
        action.payload.action === "add"
          ? state.order.tv.channels.price + action.payload.value.price
          : state.order.tv.channels.price - action.payload.value.price;

      // PRICE CALCULATION FOR CHANNELS
      calculateTotal(state);
    },
    setThemePacks: (state, action) => {
      if (action.payload.action === "add")
        state.order.tv.themePacks.selected.push(action.payload.value);
      else
        state.order.tv.themePacks.selected =
          state.order.tv.themePacks.selected.filter(
            (pack) => pack.title !== action.payload.value.title
          );

      //IF TV IS NOT SELECTED BUT CHANNEL IS, TV IS SET TO SRATER
      if (
        state.order.tv.price === 0 &&
        state.order.tv.themePacks.selected.length > 0
      ) {
        state.order.tv.price = 20;
        state.order.tv.starter = true;
      }
      // PRICE CALCULATION FOR TV CHANNELS
      state.order.tv.themePacks.price =
        action.payload.action === "add"
          ? state.order.tv.themePacks.price + action.payload.value.price
          : state.order.tv.themePacks.price - action.payload.value.price;

      // PRICE CALCULATION FOR CHANNELS
      calculateTotal(state);
    },

    setHomePhone: (state, action) => {
      for (let [key, value] of Object.entries(action.payload)) {
        state.order.homePhone[key] = value;
      }
      //PRICE CALCULATION FOR HOME PHONE
      calculateTotal(state);
    },
    setInstallationDate: (state, action) => {
      state.order.installationDate = action.payload;
    },
    setInstallationTime: (state, action) => {
      state.order.installationTime = action.payload;
    },
    setInstallationTime2: (state, action) => {
      state.order.installationTime2 = action.payload;
    },
    setNextInstallationDate: (state, action) => {
      state.order.nextInstallationDate = action.payload;
    },
    setNextInstallationTime: (state, action) => {
      state.order.nextInstallationTime = action.payload;
    },
    setHasSelectedInstallationTime: (state, action) => {
      // this value will be updated once throughout the whole order process
      state.order.hasSelectedInstallationTime = true;
    },
    setPreferredSooner: (state, action) => {
      state.order.preferredSooner = action.payload;
    },
    setPreferredSoonerComment: (state, action) => {
      state.order.preferredSoonerComment = action.payload;
    },
    setAutopayCredit: (state, action) => {
      state.order.autopayCredit = action.payload;
    },
    setEmailValidation: (state, action) => {
      if (action.payload.verification) {
        state.userInfo.emailVerificationComplete = true;
      }
      if (action.payload.email) {
        state.userInfo.email = action.payload.email;
      }
    },
    reset: (state) => {
      state.order.tv = initialState.order.tv;
    },
    setCardInfo: (state, action) => {
      for (let [key, value] of Object.entries(action.payload)) {
        state.card[key] = value;
      }
    },
    resetCreditCard: (state) => {
      state.card = initialState.card;
    },
    setUserInfo: (state, action) => {
      for (let [key, value] of Object.entries(action.payload)) {
        state.userInfo[key] = value;
      }
    },
    setUnavailableDates: (state, action) => {
      if (action.payload) {
        if (!state.order.unavailableDates.includes(action.payload))
          state.order.unavailableDates.push(action.payload);
      }
    },
    resetUnavailableDates: (state) => {
      state.order.unavailableDates = [];
    },
    resetEntireOrderSlice: (state) => {
      state.order = initialState.order;
      state.card = initialState.card;
      state.userInfo = initialState.userInfo;
    },
    setEmailStep: (state, action) => {
      state.userInfo.emailStep = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  stepPlus,
  stepMinus,
  setInternet,
  setInternetPrice,
  setTv,
  setHomePhone,
  setTvChannels,
  setTopBoxes,
  setThemePacks,
  setInstallationDate,
  setInstallationTime,
  setInstallationTime2,
  setNextInstallationDate,
  setNextInstallationTime,
  setPreferredSooner,
  setPreferredSoonerComment,
  setAutopayCredit,
  setEmailVerification,
  setEmailValidation,
  reset,
  setCardInfo,
  setUserInfo,
  setStep,
  setUnavailableDates,
  resetUnavailableDates,
  resetCreditCard,
  resetEntireOrderSlice,
  setHasSelectedInstallationTime,
  setEmailStep,
} = orderSlice.actions;

export default orderSlice.reducer;
