import React, { useEffect, useState } from "react";
import "pages/PhoneService/PhoneService.scss";
import ContactInfo from "components/reusable/ContactInfo/ContactInfo";
import ServiceDropdown from "components/reusable/Dropdown/ServiceDropdown";
import { useLocation } from "react-router-dom";
import { getQueries } from "api/wagtailAdapter";
import { usePagesApiParser } from "services/hooks/PagesAPIParser";
import { generatePhonePanels } from "components/reusable/Package/CreatePackages";
import { convertHtmlToReact } from "@hedgedoc/html-to-react/dist/convertHtmlToReact";
import { WagtailImage } from "components/Wagtail/WagtailImage";
import { useLazyGetLongDistanceRatesQuery } from "api/beanfield/OrderPage";
import { processWagtailImage } from "components/Wagtail/WagtailImage";
import Head from "components/reusable/Head/Head";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";

const PhoneService = () => {
  const [getPhoneRates, { data: phoneData }] =
    useLazyGetLongDistanceRatesQuery();
  const {
    page: info,
  } = usePagesApiParser(
    getQueries(useLocation().search).useGetServicePhonePageQuery({
      queryParams: useLocation().search,
      localePath: useLocation().pathname.split("/")[1],
      region: useLocation().pathname.split("/")[2],
    })
  );

  const [phoneRate, setPhoneRate] = useState(null);
  const [phoneDropdown, setPhoneDropdown] = useState(null);

  useEffect(() => {
    if (phoneDropdown === null) {
      let tempArr = [];
      if (navigator.userAgent !== 'ReactSnap') {
        getPhoneRates().then((res) => {
          res.data.rateGroups.map((item) => {
            let tempObj = {value: item, label: item.country};
            tempArr.push(tempObj);
          });
        });
      }

      setPhoneDropdown(tempArr);
    }
  }, [phoneDropdown]);

  const modalContent = () => {
    return (
      <>
        <div className="phone-header-title">
          <h1>{convertHtmlToReact(info?.main_banner_title)}</h1>
          <p className="phone-header-subtitle">
            {convertHtmlToReact(info?.main_banner_subtitle)}
          </p>
        </div>
        <div className="phone-banner-image">
          <div className="main-image" style={{
            backgroundImage: `url(${processWagtailImage(
              info?.main_image
            )})`,
          }}>
          </div>
          <div className="overlay-image">
            <WagtailImage image={info?.overlay_image} />
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <Head
        title={
          useLocation().pathname.split("/")[1] === "fr" ?
            "Téléphonie résidentielle | Beanfield" :
            "Home Phone | Beanfield"
        }
        description={
          useLocation().pathname.split("/")[1] === "fr" ?
            "Appelez à travers le Canada et les États-Unis grâce à la Téléphonie Résidentielle simple et abordable de Beanfield." :
            "Call across Canada & the USA with Beanfield's easy and affordable Home Phone package."
        }
        imageName={"HomePhone"}
        locale={useLocation().pathname.split("/")[1]}
      />
      <div className="resi-container">
        <Breadcrumbs route={"home-phone"}></Breadcrumbs>
      </div>
      <div>
        <div className="phone-banner-background">
          <div className="resi-container">
            <div className="resi-margin-container">
              <div className="phone-banner-container">
                {modalContent()}
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="resi-container home-phone-panel-container">
            <div className="resi-margin-container">
              {generatePhonePanels([
                {
                  plan_header: info?.plan_header,
                  plan_description: info?.plan_description,
                  plan_subtext: info?.plan_subtext,
                  plan_btn: info?.plan_btn,
                  plan_cost: info?.plan_cost,
                  plan_tax: info?.plan_tax,
                  plan_speed: info?.plan_speed,
                },
              ])}
            </div>
          </div>

          <div className="resi-container">
            <div className="resi-margin-container">
              <div className="phone-badges">
                {info?.feature_badges.map((badge) => {
                  return (
                    <div>
                      <WagtailImage height={110} image={badge?.image}/>
                      <p>{convertHtmlToReact(badge.text)}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="phone-select-container">
            <div className="resi-container">
              <div className="resi-margin-container">
                <div className="phone-select-content-container">
                  <div className="phone-select-content">
                    <h2 className="phone-select-content-title">
                      {info?.long_distance_header}
                    </h2>

                    <p className="phone-select-content-text">
                      {info?.long_distance_description}
                    </p>

                    <ServiceDropdown
                      classNamePrefix="phone-rate-dropdown"
                      className="phone-select-content-dropdown"
                      placeholder={info?.long_distance_dropdown_placeholder}
                      options={phoneDropdown}
                      BGColor={"#fff"}
                      textColor={"#000"}
                      select={(value) => {
                        setPhoneRate(value);
                      }}
                    />

                    {phoneRate === null ? null : (
                      <div className="phone-select-rate-container is-hidden-tablet-only">
                        <h2 className="phone-select-rate-title">
                          {phoneRate.value.country}
                        </h2>
                        <p className="phone-select-rate-text">
                          {info?.long_distance_calls_landline_phone}
                          <strong> ${phoneRate.value.landline}/min</strong>
                        </p>
                        <p className="phone-select-rate-text">
                          {info?.long_distance_calls_mobile_phone}
                          <strong> ${phoneRate.value.mobile}/min</strong>
                        </p>
                      </div>
                    )}
                  </div>
                  <div className="phone-select-image">
                    {phoneRate === null ? null : (
                      <div className="phone-select-rate-container is-tablet-only">
                        <h2 className="phone-select-rate-title">
                          {phoneRate.value.country}
                        </h2>
                        <p className="phone-select-rate-text">
                          {info?.long_distance_calls_landline_phone}
                          <strong> ${phoneRate.value.landline}/min</strong>
                        </p>
                        <p className="phone-select-rate-text">
                          {info?.long_distance_calls_mobile_phone}
                          <strong> ${phoneRate.value.mobile}/min</strong>
                        </p>
                      </div>
                    )}
                    <WagtailImage image={info?.long_distance_image}/>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {info?.contact_us_page?.badges?.length > 0 && (
            <ContactInfo
              title={info.contact_us_page.title}
              description={info.contact_us_page.description}
              badges={info.contact_us_page.badges}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default PhoneService;
