import React from "react";
import "pages/TermsOfService/TermsOfService.scss";
import { getQueries } from "api/wagtailAdapter";
import { usePagesApiParser } from "services/hooks/PagesAPIParser";
import { useLocation } from "react-router-dom";
import { WagTailDOMString } from "components/reusable/Link/PageLink/PageLink";
import Head from "components/reusable/Head/Head";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";

// TODO: Add page to sitemap
const CCTS = () => {
  const { page: info } = usePagesApiParser(getQueries(
    useLocation().search
  ).useGetCCTSPageQuery({
    queryParams: useLocation().search,
    localePath: useLocation().pathname.split("/")[1],
    region: useLocation().pathname.split("/")[2],
  }));


  return (
    <div>
      <Head
        title={
          useLocation().pathname.split("/")[1] === "fr" ?
            "CCTS | Beanfield" :
            "CCTS | Beanfield"
        }
        description={""}
        imageName={"Legal"}
        locale={useLocation().pathname.split("/")[1]}
      />
      <div className="resi-container">
        <Breadcrumbs route={"ccts"}></Breadcrumbs>
      </div>
      <div className="terms-header">
        <h1>{info?.header}</h1>
      </div>

      <div className="terms-container">
        <WagTailDOMString DOMString={info?.body}/>
      </div>
    </div>
  );
};

export default CCTS;
