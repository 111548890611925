import React, { useEffect, useState, useRef } from "react";
import "components/Calendar/index.scss";
import Header from "components/Calendar/Header";
import Month from "components/Calendar/Month";
import Week from "components/Calendar/Week";
import { getCalendarMatrix } from "utils/calendarMatrix";
import dayjs from "dayjs";
import {useSelector} from "react-redux";

const Calendar = ({ setMonthOfInstallation, cmsData, calendarMonthLoaded }) => {
  const { isPreorder, preOrderFirstBookingDate } = useSelector((state) => state.orderSlice.userInfo);
  let installationDate = null;
  if (isPreorder) {
    installationDate = preOrderFirstBookingDate;
  }
  const [currentMonth, setCurrentMonth] = useState(getCalendarMatrix(installationDate));
  //CHECKING IF CURRENT MONTH IS THE ONE DISPLAYED
  const currentMonthDisplayed =
    currentMonth[1][0].format("M") === dayjs().format("M") &&
    currentMonth[1][0].format("YYYY") === dayjs().format("YYYY");
  const prevMonth = () => {
    if (currentMonthDisplayed) return;
    const minusMonth = dayjs(currentMonth[1][0]).subtract(1, "month");
    setCurrentMonth(getCalendarMatrix(minusMonth));
  };
  const nextMonth = () => {
    const plusMonth = dayjs(currentMonth[1][0]).add(1, "month");
    setCurrentMonth(getCalendarMatrix(plusMonth));
  };

  useEffect(() => {
    setMonthOfInstallation(currentMonth[1][0].format("YYYY-MM-DD"));
  }, [currentMonth]);

  return (
    <div className="calendar_container">
      <Header
        currentMonth={currentMonth}
        prevMonth={prevMonth}
        nextMonth={nextMonth}
        currentMonthDisplayed={currentMonthDisplayed}
        cmsData={cmsData}
      />
      <Week calendarMonthLoaded={calendarMonthLoaded}/>
      <Month month={currentMonth} cmsData={cmsData} calendarMonthLoaded={calendarMonthLoaded}/>
    </div>
  );
};

export default Calendar;
