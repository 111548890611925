import { object, string, bool } from 'yup';

export const surveySchema = object().shape({
    cid: string().required(),
    // oid: string(),
    followup_consent: bool(),
    q1: string().required(),
    q1a: string().when("q1", {
        is: (value) => value === "No" || value === "no",
        then: (schema) => string().required()
    }),
    q2: string().required(),
    q2a: string(),
    q3: string().required(),
    q4: string().required(),
    q5: string().required(),
    q6: string(),
    q7: string().required(),
    q7a: string().required(),
    q8: string().required(),
    q8_other: string().when("q8", {
        is: (value) => value === "Other" || value === "other",
        then: (schema) => string().required()
    }),
    q9: string().required(),
});

export const getError = (questionName, errorDict) => {
    if (errorDict) {
        return errorDict[questionName];
    }
    return "";
};
/**
 * Transform Yup ValidationError to a more usable object
 */
export const transformValidationErrors = (yupValidationErrors) => {
  if (yupValidationErrors) {
  const validationErrors = {};

  yupValidationErrors.inner.forEach(error => {
    if (error.path) {
      validationErrors[error.path] = error.message;
    }
  });
  return validationErrors;
  }

}