import React from "react";
import "pages/GoService/GoService.scss";
import ContactInfo from "components/reusable/ContactInfo/ContactInfo";
import GoCard from "components/ChannelPackages/GoCard/GoCard";
import { useLocation } from "react-router-dom";
import { getQueries } from "api/wagtailAdapter";
import { usePagesApiParser } from "services/hooks/PagesAPIParser";
import { WagTailDOMString } from "components/reusable/Link/PageLink/PageLink";
import { Media, MediaContextProvider } from "utils/MediaQueries/Media";
import { processWagtailImage } from "components/Wagtail/WagtailImage";
import Head from "components/reusable/Head/Head";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";

const GoService = () => {
  const {
    page: info,
    isLoading,
    isError,
    error,
  } = usePagesApiParser(
    getQueries(useLocation().search).useGetServiceOTGPageQuery({
      queryParams: useLocation().search,
      localePath: useLocation().pathname.split("/")[1],
      region: useLocation().pathname.split("/")[2],
    })
  );

  const modalContent = () => {
    return (
      <>
        <h1>{info?.banner_header}</h1>
        <p>{info?.banner_body}</p>
      </>
    );
  };

  return (
    <MediaContextProvider>
      <Head
        title={
          useLocation().pathname.split("/")[1] === "fr" ?
            "Beanfield Télévision | Services Go" :
            "Beanfield TV | Go Services"
        }
        description={
          useLocation().pathname.split("/")[1] === "fr" ?
            "Regardez vos contenus préférés depuis n'importe quel appareil avec Beanfield Télé-Go" :
            "Bring Beanfield TV on the go and catch your favourite content from any device"
        }
        imageName={"GoTV"}
        locale={useLocation().pathname.split("/")[1]}
      />
      <div className="resi-container">
        <Breadcrumbs route={"go-services"}></Breadcrumbs>
      </div>
      <div>
        <Media greaterThanOrEqual="tablet" className="is-hidden-mobile resi-container go-banner" style={{
          backgroundImage: `url(${processWagtailImage(
            info?.banner_image_ras || info?.banner_image_svg
          )})`,
        }}>
          <div className="resi-margin-container">
            <div className="go-tv-banner-container">
              {modalContent()}
            </div>
          </div>
        </Media>
        <Media lessThan="tablet" className="is-hidden-tablet resi-container go-banner" style={{
          backgroundImage: `url(${processWagtailImage(
            info?.banner_image_mobile_ras || info?.banner_image_mobile_svg
          )})`,
        }}>
          <div className="resi-margin-container">
            <div className="go-tv-banner-container">
              {modalContent()}
            </div>
          </div>
        </Media>

        <div className="resi-container">
          <div className="resi-margin-container">
            <div className="go-text-container">
              <h2>{info?.summary_header}</h2>
              <WagTailDOMString DOMString={info?.summary_body}/>
            </div>
          </div>
        </div>

        {/* Beanfield API Hookup */}
        <div className="go-card-container">
          <div className="resi-container">
            <div className="resi-margin-container">
              <div className="go-card-content">
                {info?.channel_cards.map((card) => (
                  <GoCard card={card}/>
                ))}
              </div>
            </div>
          </div>
        </div>

        {info?.contact_us_page?.badges?.length > 0 && (
          <ContactInfo
            title={info.contact_us_page.title}
            description={info.contact_us_page.description}
            badges={info.contact_us_page.badges}
          />
        )}
      </div>
    </MediaContextProvider>
  );
};

export default GoService;
