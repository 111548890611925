import React, { useState } from "react";
import "pages/Contact/ContactPage.scss";
import ContactInfo from "components/reusable/ContactInfo/ContactInfo";
import Button from "components/reusable/Button/Button";
import EmailValidation from "utils/EmailValidation";
import PostalCodeValidation from "utils/PostalCodeValidation";
import { useLocation } from "react-router-dom";
import { getQueries } from "api/wagtailAdapter";
import { usePagesApiParser } from "services/hooks/PagesAPIParser";
import { useSubmitContactFormMutation } from "api/beanfield/ContactForm";
import Head from "components/reusable/Head/Head";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";

const Location = ({ title, address1, address2 }) => {
  return (
    <div tabIndex="0">
      <h4>{title}</h4>
      <p>{address1}</p>
      <p>{address2}</p>
    </div>
  );
};

export const TestContactSection = () => {
  const query = getQueries(useLocation().search).useGetTestContactPageQuery({
    queryParams: useLocation().search,
    localePath: useLocation().pathname.split("/")[1],
    region: useLocation().pathname.split("/")[2],
  });

  const info = query.data?.items ? query.data?.items[0] : query.data;

  return (
    <div>
      <ContactInfo
        title={info?.contact_us_header}
        description={info?.contact_us_body}
        badges={info?.contact_us}
      />
    </div>
  );
};

const ContactPage = () => {
  const [submitForm] = useSubmitContactFormMutation();
  const { page: info } = usePagesApiParser(
    getQueries(useLocation().search).useGetContactPageQuery({
      queryParams: useLocation().search,
      localePath: useLocation().pathname.split("/")[1],
      region: useLocation().pathname.split("/")[2],
    })
  );

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [messageSent, setMessageSent] = useState(false);
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [postalCodeError, setPostalCodeError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [messageError, setMessageError] = useState(false);
  const [firstNameTouched, setFirstNameTouched] = useState(false);
  const [lastNameTouched, setLastNameTouched] = useState(false);
  const [emailTouched, setEmailTouched] = useState(false);
  const [postalCodeTouched, setPostalCodeTouched] = useState(false);
  const [phoneTouched, setPhoneTouched] = useState(false);
  const [messageTouched, setMessageTouched] = useState(false);
  const [postalCodeValid, setPostalCodeValid] = useState(true);
  const [emailValid, setEmailValid] = useState(true);

  const formIsValid = () => {
    return (firstNameTouched && lastNameTouched && emailTouched && postalCodeTouched && phoneTouched && messageTouched) &&
      (!firstNameError && !lastNameError && !emailError && !postalCodeError && !phoneError && !messageError) &&
      (emailValid && postalCodeValid);
  }

  const handleMessage = () => {
    if (formIsValid()) {
      submitForm({
        firstName: firstName,
        lastName: lastName,
        email: email,
        telephone: phone,
        postalCode: postalCode,
        message: message,
      });
      setMessageSent(true);
    }
  };

  const validateField = (field) => {
    switch (field) {
      case "firstName":
        setFirstNameTouched(true);
        if (firstName === "")
          setFirstNameError(true);
        else
          setFirstNameError(false);
        break;
      case 'lastName':
        setLastNameTouched(true);
        if (lastName === "")
          setLastNameError(true);
        else
          setLastNameError(false);
        break;
      case 'email':
        setEmailTouched(true);
        if (email === "")
          setEmailError(true);
        else if (!EmailValidation(email)) {
          setEmailValid(false);
          setEmailError(false);
        }
        else {
          setEmailValid(true);
          setEmailError(false);
        }
        break;
      case 'phone':
        setPhoneTouched(true);
        if (phone === "")
          setPhoneError(true);
        else
          setPhoneError(false);
        break;
      case 'postalCode':
        setPostalCodeTouched(true);
        if (postalCode === "")
          setPostalCodeError(true);
        else if (!PostalCodeValidation(postalCode)) {
          setPostalCodeValid(false);
          setPostalCodeError(false);
        }
        else {
          setPostalCodeValid(true);
          setPostalCodeError(false);
        }
        break;
      case 'message':
        setMessageTouched(true);
        if (message === "")
          setMessageError(true);
        else
          setMessageError(false);
        break;
      default:
        return
    }
  }

  return (
    <div>
      <Head
        title={
          useLocation().pathname.split("/")[1] === "fr" ?
            "Nous contacter | Beanfield" :
            "Contact Us | Beanfield"
        }
        description={
          useLocation().pathname.split("/")[1] === "fr" ?
            "Contactez l'équipe Beanfield à tout moment. Que ce soit par téléphone ou par courriel, nous serions ravis d'avoir de vos nouvelles." :
            "Reach out to the Beanfield Team anytime. Whether through phone or email, we'd love to hear from you."
        }
        imageName={"Contact"}
        locale={useLocation().pathname.split("/")[1]}
      />
      <div className="resi-container">
        <Breadcrumbs route={"contact-us"}></Breadcrumbs>
      </div>
      <div className="resi-container">
        <div className="resi-margin-container">
          <div className="contact-header" tabIndex="0">
            <h2>{info?.main_header}</h2>
            <p>{info?.main_description}</p>
          </div>
        </div>
      </div>


      {info?.contact_section_page?.badges?.length > 0 && (
        <ContactInfo badges={info.contact_section_page.badges}/>
      )}
      <div className="resi-container">
        <div className="resi-margin-container">
          <div className="contact-location-container">
            <h3 tabIndex="0">{info?.locations_header}</h3>

            <div className="contact-location">
              {info?.locations?.map((location, index) => {
                return (
                  <div key={index}>
                    <Location
                      title={location.title}
                      address1={location.address_1}
                      address2={location.address_2}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <div className="contact-form-bg">
        <div className="resi-container">
          <div className="resi-margin-container">
            {messageSent ? (
              <div className="contact-thanks-container" tabIndex="0">
                <h3>{info?.thank_you_message}</h3>
                <p>{info?.thank_you_sub_message}</p>
              </div>
            ) : (
              <div className="contact-form-container">
                <div className="contact-form-header" tabIndex="0">
                  <h2>{info?.send_message_header}</h2>

                  <p>{info?.send_message_description}</p>

                  <p className="contact-warning-text">
                    {info?.required_field_text}
                  </p>
                </div>

                <div className="contact-form">
                  <div className="contact-split-container">
                    <div>
                      <label htmlFor="firstName">{info?.form_title_fn}</label>
                      <input
                        id="firstName"
                        onChange={(e) => setFirstName(e.target.value)}
                        onBlur={() => {
                          validateField("firstName")
                        }}
                        value={firstName}
                        aria-errormessage={firstNameError}
                        aria-label={info?.form_mandatory_fn}
                      />
                      {firstNameError ? (
                        <p className="contact-error-text" tabIndex="0">
                          {info?.form_error_fn}
                        </p>
                      ) : null}
                    </div>

                    <div>
                      <label htmlFor="lastName">{info?.form_title_ln}</label>
                      <input
                        id="lastName"
                        onChange={(e) => setLastName(e.target.value)}
                        onBlur={() => {
                          validateField("lastName")
                        }}
                        value={lastName}
                        aria-errormessage={lastNameError}
                        aria-label={info?.form_mandatory_ln}
                      />
                      {lastNameError ? (
                        <p className="contact-error-text" tabIndex="0">
                          {info?.form_error_ln}
                        </p>
                      ) : null}
                    </div>
                  </div>

                  <div>
                    <label htmlFor="email">{info?.form_title_email}</label>
                    <input
                      id="email"
                      onChange={(e) => setEmail(e.target.value)}
                      onBlur={() => {
                        validateField("email")
                      }}
                      value={email}
                      aria-errormessage={emailError || !emailValid}
                      aria-label={info?.form_mandatory_email}
                    />
                    {!emailValid ? (
                      <p className="contact-error-text" tabIndex="0">
                        {info?.form_error_valid_email}
                      </p>
                    ) : emailError ? (
                      <p className="contact-error-text" tabIndex="0">
                        {info?.form_error_empty_email}
                      </p>
                    ) : null}
                  </div>

                  <div className="contact-split-container">
                    <div>
                      <label htmlFor="postal code">
                        {info?.form_title_postcode}
                      </label>
                      <input
                        id="postal code"
                        onChange={(e) => setPostalCode(e.target.value)}
                        onBlur={() => {
                          validateField("postalCode")
                        }}
                        value={postalCode}
                        aria-errormessage={postalCodeError || !postalCodeValid}
                        aria-label={info?.form_mandatory_postcode}
                      />
                      {!postalCodeValid ? (
                        <p className="contact-error-text" tabIndex="0">
                          {info?.form_error_valid_postcode}
                        </p>
                      ) : postalCodeError ? (
                        <p className="contact-error-text" tabIndex="0">
                          {info?.form_error_empty_postcode}
                        </p>
                      ) : null}
                    </div>

                    <div>
                      <label htmlFor="phone">{info?.form_title_phone}</label>
                      <input
                        id="phone"
                        onChange={(e) => setPhone(e.target.value)}
                        onBlur={() => {
                          validateField("phone")
                        }}
                        value={phone}
                        aria-errormessage={phoneError}
                        aria-label={info?.form_mandatory_phone}
                      />
                      {phoneError ? (
                        <p className="contact-error-text" tabIndex="0">
                          {info?.form_error_phone}
                        </p>
                      ) : null}
                    </div>
                  </div>

                  <div>
                    <label htmlFor="message">{info?.form_title_message}</label>
                    <textarea
                      id="message"
                      onChange={(e) => setMessage(e.target.value)}
                      onBlur={() => {
                        validateField("message")
                      }}
                      value={message}
                      aria-errormessage={messageError}
                      aria-label={info?.form_mandatory_message}
                    />
                    {messageError ? (
                      <p className="contact-error-text" tabIndex="0">
                        {info?.form_error_message}
                      </p>
                    ) : null}
                  </div>
                </div>

                <Button style={{width: 210}} click={() => handleMessage()} disabled={!formIsValid()}>
                  {info?.form_btn_send}
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
