import React, {useEffect, useState} from "react";
import "components/Breadcrumbs/Breadcrumbs.scss";
import BreadcrumbsCaret from "assets/Icons/BreadcrumbsCaret";
import { internalUrlReaderWithLangReg } from "utils/wagtail";
import { useLocation } from "react-router";

const BreadcrumbsConstant = [
  {
    name: 'home',
    language: [
      {
        en: [
          {
            text: "Home",
            href: '',
          }
        ],
        fr: [
          {
            text: "Home",
            href: '',
          }
        ]
      }
    ],
  },
  {
    name: 'internet',
    language: [
      {
        en: [
          {
            text: "Home",
            href: 'https://internallink.com/residential',
          },
          {
            text: "Shop: Internet",
            href: '',
          }
        ],
        fr: [
          {
            text: "Accueil",
            href: 'https://internallink.com/residential',
          },
          {
            text: "Magaziner: Internet",
            href: '',
          }
        ],
      }
    ],
  },
  {
    name: 'reality-of-internet-speed',
    language: [
      {
        en: [
          {
            text: "Home",
            href: 'https://internallink.com/residential',
          },
          {
            text: "Shop: Internet",
            href: 'https://internallink.com/residential/internet',
          },
          {
            text: "Reality of Internet Speed",
            href: '',
          },
        ],
        fr: [
          {
            text: "Accueil",
            href: 'https://internallink.com/residential',
          },
          {
            text: "Magaziner: Internet",
            href: 'https://internallink.com/residential/internet',
          },
          {
            text: "La réalité de la vitesse internet",
            href: '',
          },
        ],
      }
    ],
  },
  {
    name: 'about-us',
    language: [
      {
        en: [
          {
            text: "Home",
            href: 'https://internallink.com/residential',
          },
          {
            text: "About Beanfield: Our Story",
            href: '',
          },
        ],
        fr: [
          {
            text: "Accueil",
            href: 'https://internallink.com/residential',
          },
          {
            text: "À propos de Beanfield: Notre histoire",
            href: '',
          }
        ],
      }
    ],
  },
  {
    name: 'life-at-beanfield',
    language: [
      {
        en: [
          {
            text: "Home",
            href: 'https://internallink.com/residential',
          },
          {
            text: "About Beanfield: Our Story",
            href: 'https://internallink.com/residential/about-us',
          },
          {
            text: "Life at Beanfield",
            href: '',
          },
        ],
        fr: [
          {
            text: "Accueil",
            href: 'https://internallink.com/residential',
          },
          {
            text: "À propos de Beanfield: Notre histoire",
            href: 'https://internallink.com/residential/about-us',
          },
          {
            text: "La vie à Beanfield",
            href: '',
          },
        ],
      }
    ],
  },
  {
    name: 'why-choose-us',
    language: [
      {
        en: [
          {
            text: "Home",
            href: 'https://internallink.com/residential',
          },
          {
            text: "About Beanfield: Why Choose Us",
            href: '',
          },
        ],
        fr: [
          {
            text: "Accueil",
            href: 'https://internallink.com/residential',
          },
          {
            text: "À propos de Beanfield: Pourquoi Beanfield",
            href: '',
          }
        ],
      }
    ],
  },
  {
    name: 'public-wifi',
    language: [
      {
        en: [
          {
            text: "Home",
            href: 'https://internallink.com/residential',
          },
          {
            text: "About Beanfield: Out and About Public WiFi",
            href: '',
          }
        ],
        fr: [
          {
            text: "Accueil",
            href: 'https://internallink.com/residential',
          },
          {
            text: "À propos de Beanfield: WiFi public 'Out and About'",
            href: '',
          }
        ],
      }
    ],
  },
  {
    name: 'internet-tech-hardware',
    language: [
      {
        en: [
          {
            text: "Home",
            href: 'https://internallink.com/residential',
          },
          {
            text: "Internet Tech Hardware",
            href: '',
          }
        ],
        fr: [
          {
            text: "Accueil",
            href: 'https://internallink.com/residential',
          },
          {
            text: "Haute technologie pour la haute vitesse",
            href: '',
          }
        ],
      }
    ],
  },
  {
    name: 'home-phone',
    language: [
      {
        en: [
          {
            text: "Home",
            href: 'https://internallink.com/residential',
          },
          {
            text: "Shop: Home Phone",
            href: '',
          }
        ],
        fr: [
          {
            text: "Accueil",
            href: 'https://internallink.com/residential',
          },
          {
            text: "Magasiner: Téléphonie Résidentielle",
            href: '',
          }
        ],
      }
    ],
  },
  {
    name: 'tv',
    language: [
      {
        en: [
          {
            text: "Home",
            href: 'https://internallink.com/residential',
          },
          {
            text: "Shop: TV",
            href: '',
          }
        ],
        fr: [
          {
            text: "Accueil",
            href: 'https://internallink.com/residential',
          },
          {
            text: "Magasiner: Télévision",
            href: '',
          }
        ],
      }
    ],
  },
  {
    name: 'go-services',
    language: [
      {
        en: [
          {
            text: "Home",
            href: 'https://internallink.com/residential',
          },
          {
            text: "Shop: TV",
            href: 'https://internallink.com/residential/tv',
          },
          {
            text: "Go Services",
            href: '',
          },
        ],
        fr: [
          {
            text: "Accueil",
            href: 'https://internallink.com/residential',
          },
          {
            text: "Magasiner: Télévision",
            href: 'https://internallink.com/residential/tv',
          },
          {
            text: "Services Go",
            href: '',
          },
        ],
      }
    ],
  },
  {
    name: 'channels',
    language: [
      {
        en: [
          {
            text: "Home",
            href: 'https://internallink.com/residential',
          },
          {
            text: "Shop: TV",
            href: 'https://internallink.com/residential/tv',
          },
          {
            text: "TV Channel Lineup",
            href: '',
          },
        ],
        fr: [
          {
            text: "Accueil",
            href: 'https://internallink.com/residential',
          },
          {
            text: "Magasiner: Télévision",
            href: 'https://internallink.com/residential/tv',
          },
          {
            text: "Gamme de chaînes de télévision",
            href: '',
          },
        ],
      }
    ],
  },
  {
    name: 'support',
    language: [
      {
        en: [
          {
            text: "Home",
            href: 'https://internallink.com/residential',
          },
          {
            text: "Support",
            href: '',
          },
        ],
        fr: [
          {
            text: "Accueil",
            href: 'https://internallink.com/residential',
          },
          {
            text: "Soutien",
            href: '',
          }
        ],
      }
    ],
  },
  {
    name: 'support-billing',
    language: [
      {
        en: [
          {
            text: "Home",
            href: 'https://internallink.com/residential',
          },
          {
            text: "Support",
            href: 'https://internallink.com/residential/support',
          },
          {
            text: "Support: Billing",
            href: '',
          },
        ],
        fr: [
          {
            text: "Accueil",
            href: 'https://internallink.com/residential',
          },
          {
            text: "Soutien",
            href: 'https://internallink.com/residential/support',
          },
          {
            text: "Soutien: Facturation",
            href: '',
          }
        ],
      }
    ],
  },
  {
    name: 'support-internet',
    language: [
      {
        en: [
          {
            text: "Home",
            href: 'https://internallink.com/residential',
          },
          {
            text: "Support",
            href: 'https://internallink.com/residential/support',
          },
          {
            text: "Support: Internet",
            href: '',
          },
        ],
        fr: [
          {
            text: "Accueil",
            href: 'https://internallink.com/residential',
          },
          {
            text: "Soutien",
            href: 'https://internallink.com/residential/support',
          },
          {
            text: "Soutien: Internet",
            href: '',
          }
        ],
      }
    ],
  },
  {
    name: 'support-home-phone',
    language: [
      {
        en: [
          {
            text: "Home",
            href: 'https://internallink.com/residential',
          },
          {
            text: "Support",
            href: 'https://internallink.com/residential/support',
          },
          {
            text: "Support: Home Phone",
            href: '',
          },
        ],
        fr: [
          {
            text: "Accueil",
            href: 'https://internallink.com/residential',
          },
          {
            text: "Soutien",
            href: 'https://internallink.com/residential/support',
          },
          {
            text: "Soutien: Téléphonie Résidentielle",
            href: '',
          }
        ],
      }
    ],
  },
  {
    name: 'support-tv',
    language: [
      {
        en: [
          {
            text: "Home",
            href: 'https://internallink.com/residential',
          },
          {
            text: "Support",
            href: 'https://internallink.com/residential/support',
          },
          {
            text: "Support: TV",
            href: '',
          },
        ],
        fr: [
          {
            text: "Accueil",
            href: 'https://internallink.com/residential',
          },
          {
            text: "Soutien",
            href: 'https://internallink.com/residential/support',
          },
          {
            text: "Soutien: Télévision",
            href: '',
          }
        ],
      }
    ],
  },
  {
    name: 'support-general-accounts',
    language: [
      {
        en: [
          {
            text: "Home",
            href: 'https://internallink.com/residential',
          },
          {
            text: "Support",
            href: 'https://internallink.com/residential/support',
          },
          {
            text: "Support: General Accounts",
            href: '',
          },
        ],
        fr: [
          {
            text: "Accueil",
            href: 'https://internallink.com/residential',
          },
          {
            text: "Soutien",
            href: 'https://internallink.com/residential/support',
          },
          {
            text: "Soutien: Général et comptabilité",
            href: '',
          }
        ],
      }
    ],
  },
  {
    name: 'internet-speeds-101',
    language: [
      {
        en: [
          {
            text: "Home",
            href: 'https://internallink.com/residential',
          },
          {
            text: "Internet Speeds 101",
            href: '',
          },
        ],
        fr: [
          {
            text: "Accueil",
            href: 'https://internallink.com/residential',
          },
          {
            text: "Vitesses internet 101",
            href: '',
          },
        ],
      }
    ],
  },
  {
    name: 'availability-map',
    language: [
      {
        en: [
          {
            text: "Home",
            href: 'https://internallink.com/residential',
          },
          {
            text: "Availability Map",
            href: '',
          },
        ],
        fr: [
          {
            text: "Accueil",
            href: 'https://internallink.com/residential',
          },
          {
            text: "Carte de disponibilité",
            href: '',
          },
        ],
      }
    ],
  },
  {
    name: 'legal',
    language: [
      {
        en: [
          {
            text: "Home",
            href: 'https://internallink.com/residential',
          },
          {
            text: "Legal",
            href: '',
          },
        ],
        fr: [
          {
            text: "Accueil",
            href: 'https://internallink.com/residential',
          },
          {
            text: "Mentions juridiques",
            href: '',
          },
        ],
      }
    ],
  },
  {
    name: 'ccts',
    language: [
      {
        en: [
          {
            text: "Home",
            href: 'https://internallink.com/residential',
          },
          {
            text: "Escalate your concern",
            href: '',
          },
        ],
        fr: [
          {
            text: "Accueil",
            href: 'https://internallink.com/residential',
          },
          {
            text: "Faites remonter votre préoccupation",
            href: '',
          },
        ],
      }
    ],
  },
  {
    name: 'aup',
    language: [
      {
        en: [
          {
            text: "Home",
            href: 'https://internallink.com/residential',
          },
          {
            text: "Escalate your concern",
            href: '',
          },
        ],
        fr: [
          {
            text: "Accueil",
            href: 'https://internallink.com/residential',
          },
          {
            text: "Faites remonter votre préoccupation",
            href: '',
          },
        ],
      }
    ],
  },
  {
    name: 'mdu',
    language: [
      {
        en: [
          {
            text: "Home",
            href: 'https://internallink.com/residential',
          },
          {
            text: "MDU Access Agreements",
            href: '',
          },
        ],
        fr: [
          {
            text: "Accueil",
            href: 'https://internallink.com/residential',
          },
          {
            text: "Faites remonter votre préoccupation",
            href: '',
          },
        ],
      }
    ],
  },
  {
    name: 'accessibility',
    language: [
      {
        en: [
          {
            text: "Home",
            href: 'https://internallink.com/residential',
          },
          {
            text: "Accessibility",
            href: '',
          },
        ],
        fr: [
          {
            text: "Accueil",
            href: 'https://internallink.com/residential',
          },
          {
            text: "Accessibilité",
            href: '',
          },
        ],
      }
    ],
  },
  {
    name: 'contact-us',
    language: [
      {
        en: [
          {
            text: "Home",
            href: 'https://internallink.com/residential',
          },
          {
            text: "Contact Us",
            href: '',
          },
        ],
        fr: [
          {
            text: "Accueil",
            href: 'https://internallink.com/residential',
          },
          {
            text: "Contactez-nous",
            href: '',
          },
        ],
      }
    ],
  },
  {
    name: 'careers',
    language: [
      {
        en: [
          {
            text: "Home",
            href: 'https://internallink.com/residential',
          },
          {
            text: "Careers",
            href: '',
          },
        ],
        fr: [
          {
            text: "Accueil",
            href: 'https://internallink.com/residential',
          },
          {
            text: "Carrières",
            href: '',
          },
        ],
      }
    ],
  },
  {
    name: 'how-we-hire',
    language: [
      {
        en: [
          {
            text: "Home",
            href: 'https://internallink.com/residential',
          },
          {
            text: "Careers",
            href: 'https://internallink.com/residential/careers',
          },
          {
            text: "Careers: How We Hire",
            href: '',
          },
        ],
        fr: [
          {
            text: "Accueil",
            href: 'https://internallink.com/residential',
          },
          {
            text: "Carrières",
            href: 'https://internallink.com/residential/careers',
          },
          {
            text: "Carrières: Notre processus d’embauche",
            href: '',
          },
        ],
      }
    ],
  },
  {
    name: 'tos',
    language: [
      {
        en: [
          {
            text: "Home",
            href: 'https://internallink.com/residential',
          },
          {
            text: "Terms of Service",
            href: '',
          },
        ],
        fr: [
          {
            text: "Accueil",
            href: 'https://internallink.com/residential',
          },
          {
            text: "Entente de services de communications résidentiels",
            href: '',
          },
        ],
      }
    ],
  },
  {
    name: 'search-results',
    language: [
      {
        en: [
          {
            text: "Home",
            href: 'https://internallink.com/residential',
          },
          {
            text: "Search Results",
            href: '',
          },
        ],
        fr: [
          {
            text: "Accueil",
            href: 'https://internallink.com/residential',
          },
          {
            text: "Résultats de recherche",
            href: '',
          },
        ],
      }
    ],
  },
]

const handleInternalLinks = (link) => {
  let uri;
  const language = localStorage.getItem("language");
  const region = localStorage.getItem("region");
  if (link.includes("https://internallink.com")) {
    uri =
      "/" + internalUrlReaderWithLangReg(link, `/${language}/${region}/`);
  } else {
    uri = link;
  }
  return uri;
};

const Breadcrumbs = (route) => {
  const [breadcrumb, setBreadcrumb] = useState([]);
  const localePath = useLocation().pathname.split("/")[1]

  useEffect(() => {
    const bc = getBreadcrumb(route)
    setBreadcrumb(bc.language[0][localePath])
  }, [route]);

  const getBreadcrumb = (route) => {
    return BreadcrumbsConstant?.find((breadcrumb) => {
      return breadcrumb.name === route.route
    })
  }

  return (
    <div className="breadcrumbs-container">
      {breadcrumb?.map((item, index) => {
        return (
          <div className="item-container" key={index}>
            {item.href ? (
              <>
                <div className="item">
                  <a href={handleInternalLinks(item.href)}>
                    {item.text}
                  </a>
                </div>
                <div className="caret">
                  <BreadcrumbsCaret color={"#000000"} turn={"right"} height={24}/>
                </div>
              </>
            ) : (
              <div className="item active">{item.text}</div>
            )}
          </div>
        );
      })}
    </div>
  );
}

export default Breadcrumbs;
