let internetPackageIds = [8790, 8792, 8791];
let googleEventMap = {
    8790: '1gbps Availability Check',
    8792: '3gbps Availability Check',
    8791: '8gbps Availability Check',
};

/*
* These are the IDs to use locally and staging (they should have the same numbers but if you want to change that for
* testing/debug remember to change back to staging IDs for them not freak out when they test it
*/
let singlePromoPackageID = ""
let promoPackageIds = [2040, 4967, 4968, 4206]

if (process.env.REACT_APP_NODE_ENV === 'production' ) {
    internetPackageIds = [2255, 2256, 2257];
    googleEventMap = {
        2255: '1gbps Availability Check',
        2256: '3gbps Availability Check',
        2257: '8gbps Availability Check',
    };

    /*
    * These are the production package ids they gave us for the email gift promo.
    * I'm just going to leave this here in case Marketing comes up with some last minute thing as
    * on Black Friday but this shouldn't be necessary since they didn't give us anything yet
    */
    singlePromoPackageID = ""
    promoPackageIds = [2131, 2132, 2133, 2134, 2135, 2136, 2137, 2146]
}

export {
    internetPackageIds,
    googleEventMap,
    singlePromoPackageID,
    promoPackageIds
}
