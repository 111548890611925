import React, { useEffect, useState } from "react";
import Day from "components/Calendar/Day";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import {
  setInstallationDate,
  setInstallationTime,
} from "store/reducers/order/orderSlice";
import { sendSelectedDayEvent } from "services/hooks/GoogleTags";

const Month = ({ month, cmsData, calendarMonthLoaded }) => {
  const dispatch = useDispatch();
  const { installationTime, installationDate } = useSelector(
    (state) => state.orderSlice.order
  );
  const { isPreorder, preOrderFirstBookingDate } = useSelector(
    (state) => state.orderSlice.userInfo
  );

  //DEFAULT INSTALLATION DATE TO TODAY
  useEffect(() => {
    if (!installationTime && !installationDate) {
      if (isPreorder) {
        dispatch(setInstallationDate(dayjs(preOrderFirstBookingDate)));
      } else {
        dispatch(setInstallationDate(dayjs()));
      }
    }
  }, []);

  return (
    <div className="month" style={{ gridTemplateRows: `${month?.length} 1fr`,visibility: calendarMonthLoaded ? 'visible' : 'hidden' }}>
      {month.map((week, index) => {
        return (
          <React.Fragment key={index}>
            {week.map((day, index) => {
              return (
                <Day
                  key={index}
                  day={day}
                  month={month}
                  cmsData={cmsData}
                  onClick={(dayPassedIn) => {
                    dispatch(setInstallationDate(dayPassedIn));
                    sendSelectedDayEvent(dayPassedIn);
                    //IF INSTALLATION TIME IS SELECTED, RESET IT WHEN INSTALLATION DATE IS CHANGED
                    if (installationTime) dispatch(setInstallationTime(null));
                  }}
                />
              );
            })}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default Month;
