import React from "react";
import "pages/Technical/Technical.scss";
import PageLink from "components/reusable/Link/PageLink/PageLink";
import ContactInfo from "components/reusable/ContactInfo/ContactInfo";
import { getQueries } from "api/wagtailAdapter";
import { usePagesApiParser } from "services/hooks/PagesAPIParser";
import { useLocation } from "react-router-dom";
import { convertHtmlToReact } from "@hedgedoc/html-to-react/dist/convertHtmlToReact";
import { WagtailImage } from "components/Wagtail/WagtailImage";
import Head from "components/reusable/Head/Head";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";

const APIMessageInfoBox = (info_box, index) => {
  return {
    header: info_box.header,
    subHeader: info_box.sub_header,
    image: info_box.img,
    body: info_box.body,
    centeredFooter: info_box.centered_footer,
    centeredLink: info_box.centered_link,
    centeredLinkText: info_box.centered_link_txt,
    boxIndex : index
  };
};

const InfoBox = ({
  header,
  subHeader,
  image,
  body,
  centeredFooter,
  centeredLink,
  centeredLinkText,
  boxIndex
}) => {

  return (
    <div className={`technical-package ${boxIndex > 1? "last-info-box": ""}`}>
      <div>
        <h3>{header}</h3>
        <p className="technical-package-subtitle">{subHeader}</p>
      </div>
      <div className={`content-wrapper ${boxIndex > 1? "content-wrapper-last-box": ""}`}>
        <div className="img-container"><WagtailImage image={image} /></div>

        <div className="content">{convertHtmlToReact(body)}</div>

        {centeredFooter && centeredLink && centeredLinkText && (
          <div className="technical-link-container">
            <p className="Wbold">{centeredFooter}</p>

            <PageLink arrow={"true"} to={centeredLink}>
              {centeredLinkText}
            </PageLink>
          </div>
        )}
      </div>
    </div>
  );
};

const FeatureBar = ({ img, header, body, background }) => {
  return (
    <div style={{ background: background }}>
      <div className="resi-container">
        <div className="resi-margin-container">
          <div className="technical-info-container">
            <WagtailImage height={210} image={img} />

            <div>
              <h3>{header}</h3>

              <p>{body}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Technical = () => {
  const {
    page: info,
    isLoading,
    isError,
    error,
  } = usePagesApiParser(
    getQueries(useLocation().search).useGetTechnicalPageQuery({
      queryParams: useLocation().search,
      localePath: useLocation().pathname.split("/")[1],
      region: useLocation().pathname.split("/")[2],
    })
  );

  return (
    <div>
      <Head
        title={
          useLocation().pathname.split("/")[1] === "fr" ?
            "Technologie et matériel Internet" :
            "Internet Tech and Hardware | Beanfield"
        }
        description={
          useLocation().pathname.split("/")[1] === "fr" ?
            "Découvrez les routeurs et les points d'accès WiFi qui alimentent notre Internet ultra-rapide." :
            "Explore the routers and WiFi Access Point that powers our ultra-fast Internet"
        }
        imageName={"Tech"}
        locale={useLocation().pathname.split("/")[1]}
      />
      <div className="resi-container">
        <Breadcrumbs route={"internet-tech-hardware"}></Breadcrumbs>
      </div>
      <div className="resi-container">
        <div className="resi-margin-container">
          <div className="technical-header">
            <h1>{info?.banner_header}</h1>
            <p>{info?.banner_body}</p>
          </div>
        </div>
      </div>

      <div className="resi-container">
        <div className="resi-margin-container">
          <h2 className="technical-title">{info?.body_header}</h2>
          <div className="technical-package-container">
            {info?.info_boxes?.map((info_box, index) => {
              return React.createElement(InfoBox, APIMessageInfoBox(info_box, index));
            })}
          </div>
        </div>
      </div>

      {info?.feature_bars?.map((feature_bar, i) => {
        return React.createElement(FeatureBar, {
          ...feature_bar,
          background: i % 2 === 1 ? "#e8e3f1" : "#effdd2",
        });
      })}

      {info?.contact_us_page?.badges?.length > 0 && (
        <ContactInfo
          title={info.contact_us_page.title}
          description={info.contact_us_page.description}
          badges={info.contact_us_page.badges}
        />
      )}
    </div>
  );
};

export default Technical;
