import { useDispatch } from "react-redux";
import {
  resetEntireOrderSlice, setAutopayCredit, setInternet,
  setUserInfo,
} from "store/reducers/order/orderSlice";

export const useResetStateAndSetAddress = () => {
  const dispatch = useDispatch();
  /**
   * @param {Object} info - address to set
   * @returns void
   */
  const resetStateAndSetAddress = (info) => {
    dispatch(resetEntireOrderSlice());
    dispatch(
      setUserInfo({
        address:
          info.addressText ||
          `${info?.streetNumber} ${info?.streetName} ${info?.streetTypeAbbr} ${info?.streetDirection} ${info?.city} ${info?.postalCode}`,
        address_id: info.addressId,
        province: info.province,
        unit: info?.unit || "",
        unitHasService: !!info?.activeServiceUnits?.includes(info?.unit),
        isPreorder: info?.isPreorder,
        onNetDate: info?.onNetDate,
        preOrderFirstBookingDate: info?.preOrderFirstBookingDate
      })
    );
    dispatch(
      setInternet({
        internetPackageSelected: info?.internetSelected
      })
    );
    dispatch(
      setAutopayCredit(0)
    );
  };

  return { resetStateAndSetAddress };
};
