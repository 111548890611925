export const getAutopayCredit = (internetPackage) => {
    if (internetPackage && 'Autopay Credit' in internetPackage?.includedServices){
        return internetPackage?.includedServices['Autopay Credit'].price;
    }
    return 0;
};

export const getInternetPriceWithDiscount = (internetPackage) => {
    let price = 0;
    for (const svc in internetPackage?.includedServices) {
       if (internetPackage?.includedServices[svc].isDiscountedService){
        price += internetPackage?.includedServices[svc].price
      }
    }
    return price;
}
