import React, { useState } from "react";
import "pages/WhyChooseUs/WhyChooseUs.scss";
import ContactInfo from "components/reusable/ContactInfo/ContactInfo";
import { getQueries } from "api/wagtailAdapter";
import { usePagesApiParser } from "services/hooks/PagesAPIParser";
import { useLocation } from "react-router-dom";
import { convertHtmlToReact } from "@hedgedoc/html-to-react/dist/convertHtmlToReact";
import PageLink from "components/reusable/Link/PageLink/PageLink";
import { WagtailImage } from "components/Wagtail/WagtailImage";
import { MediaContextProvider } from "utils/MediaQueries/Media";
import Head from "components/reusable/Head/Head";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";

const WhyChooseUs = () => {
  const {
    page: info,
    isLoading,
    isError,
    error,
  } = usePagesApiParser(
    getQueries(useLocation().search).useGetWhyUsPageQuery({
      queryParams: useLocation().search,
      localePath: useLocation().pathname.split("/")[1],
      region: useLocation().pathname.split("/")[2],
    })
  );

  const modalContent = () => {
    return (
      <>
        <h1>{info?.banner_text}</h1>
      </>
    );
  };

  return (
    <MediaContextProvider>
      <Head
        title={
          useLocation().pathname.split("/")[1] === "fr" ?
            "Pourquoi nous choisir | Beanfield" :
            "Why Choose Us | Beanfield"
        }
        description={
          useLocation().pathname.split("/")[1] === "fr" ?
            "Il est facile de choisir Beanfield." :
            "We make it easy to choose Beanfield."
        }
        imageName={"ChooseUs"}
        locale={useLocation().pathname.split("/")[1]}
      />
      <div className="resi-container">
        <Breadcrumbs route={"why-choose-us"}></Breadcrumbs>
      </div>
      <div>
        <div className="resi-container">
          <div className="resi-margin-container">
            <div className="why-choose-us-header">
              <h1>{info?.banner_text}</h1>
            </div>
          </div>
        </div>

        <div className="resi-container">
          <div className="resi-margin-container">
            <div className="why-choose-us-text-container">
              <WagtailImage
                style={{
                  width: 318,
                  height: 314,
                }}
                image={info?.wide_section_1_badge}
                alt={info?.wide_section_1_badge?.title}
              />

              <div>
                <h2>{info?.wide_section_1_header}</h2>
                <p>{info?.wide_section_1_body}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="resi-container">
          <div className="resi-margin-container">
            <div className="why-choose-us-badge-container">
              <div
                className="why-choose-us-badge"
                style={{background: "#fffcd9"}}
              >
                <WagtailImage
                  image={info?.panel_section_1a_image}
                  alt={info?.panel_section_1a_image?.title}
                />

                <h3>{info?.panel_section_1a_header}</h3>
                <p>{info?.panel_section_1a_body}</p>
              </div>

              <div
                className="why-choose-us-badge"
                style={{background: "#f6e8ed"}}
              >
                <WagtailImage
                  image={info?.panel_section_1b_image}
                  alt={info?.panel_section_1b_image?.title}
                />

                <h3>{info?.panel_section_1b_header}</h3>
                <p>{info?.panel_section_1b_body}</p>
              </div>
            </div>
          </div>
        </div>


        <div className="resi-container">
          <div className="resi-margin-container">
            <div className="why-choose-us-text-container">
              <WagtailImage
                style={{
                  width: 318,
                  height: 314,
                }}
                image={info?.wide_section_2_badge}
                alt={info?.wide_section_2_badge?.title}
              />

              <div>
                <h2>{info?.wide_section_2_header}</h2>

                {info?.wide_section_2_body &&
                  convertHtmlToReact(info?.wide_section_2_body)}
                <PageLink bold arrow={"true"} to={info?.wide_section_2_link}>
                  {info?.wide_section_2_link_txt}
                </PageLink>
              </div>
            </div>
          </div>
        </div>

        <div className="resi-container">
          <div className="resi-margin-container">
            <div className="why-choose-us-badge-container">
              <div
                className="why-choose-us-badge"
                style={{background: "#e0d9ec"}}
              >
                <WagtailImage
                  image={info?.panel_section_2a_image}
                  alt={info?.panel_section_2a_image?.title}
                />

                <h3>{info?.panel_section_2a_header}</h3>
                <p>{info?.panel_section_2a_body}</p>
              </div>

              <div
                className="why-choose-us-badge"
                style={{background: "#eafcc3"}}
              >
                <WagtailImage
                  image={info?.panel_section_2b_image}
                  alt={info?.panel_section_2b_image?.title}
                />

                <h3>{info?.panel_section_2b_header}</h3>
                <p>{info?.panel_section_2b_body}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="resi-container">
          <div className="resi-margin-container">
            <div className="why-choose-us-text-container">
              <WagtailImage
                style={{
                  width: 318,
                  height: 314,
                }}
                image={info?.wide_section_3_badge}
                alt={info?.wide_section_3_badge?.title}
              />

              <div>
                <h2>{info?.wide_section_3_header}</h2>

                {info?.wide_section_3_body &&
                  convertHtmlToReact(info?.wide_section_3_body)}
              </div>
            </div>
          </div>
        </div>

        <div className="resi-container">
          <div className="resi-margin-container">
            <div className="why-choose-us-badge-container">
              <div
                className="why-choose-us-badge"
                style={{background: "#fee3d7"}}
              >
                <WagtailImage
                  image={info?.panel_section_3a_image}
                  alt={info?.panel_section_3a_image?.title}
                />

                <h3>{info?.panel_section_3a_header}</h3>
                <p>{info?.panel_section_3a_body}</p>
              </div>

              <div
                className="why-choose-us-badge"
                style={{background: "#fffcd9"}}
              >
                <WagtailImage
                  image={info?.panel_section_3b_image}
                  alt={info?.panel_section_3b_image?.title}
                />

                <h3>{info?.panel_section_3b_header}</h3>
                <p>{info?.panel_section_3b_body}</p>
              </div>
            </div>
          </div>
        </div>

        {info?.contact_us_page?.badges?.length > 0 && (
          <ContactInfo
            title={info.contact_us_page.title}
            description={info.contact_us_page.description}
            badges={info.contact_us_page.badges}
          />
        )}
      </div>
    </MediaContextProvider>
  );
};

export default WhyChooseUs;
