import React, { useEffect, useState } from "react";
import { Helmet } from 'react-helmet-async';

const Head = ({title = "Beanfield", description, imageName, locale}) => {
  const [headTitle, setHeadTitle] = useState(title);
  const [headDescription, setHeadDescription] = useState(description);
  const [headLocale, setHeadLocale] = useState(locale);
  const [ogImage, setOgImage] = useState('');
  const [twitterImage, setTwitterImage] = useState('');
  const addCanonical = `${window.location.href}/`;

  useEffect(() => {
    setHeadTitle(title);
    setHeadDescription(description);
    setHeadLocale(locale);
    setOgImage(`https://static.beanfield.com/img/social-media-banners/${imageName}_1200x630_${headLocale.toUpperCase()}.png`);
    setTwitterImage(`https://static.beanfield.com/img/social-media-banners/${imageName}_200x200_${headLocale.toUpperCase()}.png`);
  }, [title, description, imageName, locale]);

  return (
    <Helmet prioritizeSeoTags>
      { /* Standard metadata tags */ }
      <title>{`${headTitle}`}</title>
      <meta name='description' content={description} />
      { /* End standard metadata tags */ }
      { /* Facebook tags */ }
      <meta property="og:type" content="website" />
      <meta property="og:title" content={headTitle} />
      <meta property="og:description" content={headDescription} />
      <meta property="og:image" content={ogImage} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      { /* End Facebook tags */ }
      { /* Twitter tags */ }
      <meta name="twitter:card" content="website" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={twitterImage} />
      <meta name="twitter:site" content="@beanfieldcanada" />
      { /* End Twitter tags */ }

      {/*Canonical */}
      <link rel="canonical" href={addCanonical} />
    </Helmet>
  );
};

export default Head;