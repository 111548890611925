import dayjs from "dayjs";
import React, { useEffect, useRef } from "react";
import "components/Modals/PreOrder/PreOrder.scss";
import Button from "components/reusable/Button/Button";
import { useDispatch } from "react-redux";
import { toggleModal } from "store/reducers/modal/modalSlice";
import AddressModal from "components/Modals/Address/Address";
import { useLocation, useNavigate } from "react-router-dom";
import { convertHtmlToReact } from "@hedgedoc/html-to-react/dist/convertHtmlToReact";
import ExclamationMark from "assets/svg/ExclamationMark";
import ToolTip from "components/reusable/Tooltip/Tooltip";
import {usePagesApiParser} from "services/hooks/PagesAPIParser";
import {getQueries} from "api/wagtailAdapter";

const PreOrderModal = ({
  province,
  address,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const preorderModalRef = useRef(null);
  const baseLink = location.pathname.split("/");
  const width =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;

  const provinceData = {
    bc: {
      name: "Metro Vancouver",
      region: "van",
    },
    on: {
      name: "Toronto/GTA",
      region: "tor",
    },
    qc: {
      name: "Montréal Area",
      region: "mtl",
    },
  };

  const provinceDataFR = {
    bc: {
      name: "Région de Vancouver",
      region: "van",
    },
    on: {
      name: "Région de Toronto",
      region: "tor",
    },
    qc: {
      name: "Région de Montréal",
      region: "mtl",
    },
  };

  const handleReturn = () => {
    dispatch(toggleModal());
    dispatch(toggleModal({ children: <AddressModal /> }));
  };

  const handleStart = () => {
    dispatch(toggleModal());
    navigate(`/${baseLink[1]}/${baseLink[2]}/residential-order`);
  };

  const handleSwitchRegion = () => {
    dispatch(toggleModal());
    const region =
      baseLink[1] === "fr"
        ? provinceDataFR[province].region
        : provinceData[province].region;
    navigate(`/${baseLink[1]}/${region}/residential`);
  };

  const handleShowRegionWarning = () => {
    const region =
      baseLink[1] === "fr"
        ? provinceDataFR[province].region
        : provinceData[province].region;
    if (region !== baseLink[2]) {
      return true;
    }
  };

  const switchRegion = () => {
    return (
      <div
        style={{ justifyContent: "flex-start" }}
        className="switch-region-container"
      >
        <div className="left-content">
          <ExclamationMark />
        </div>
        <div className="right-content">
          <p style={{ flex: 1 }}>
            {cmsData?.modal_preorder_switch_region_text}{" "}
            {baseLink[1] === "fr"
              ? provinceDataFR[province].name
              : provinceData[province].name}
            ,{" "}
            <span onClick={() => handleSwitchRegion()}>
              {cmsData?.modal_preorder_switch_region_link_text}
            </span>
            .
          </p>
        </div>
      </div>
    );
  };

  const checkIsXGSPON = () => {
    return address?.accessPlatforms?.includes('XGS-PON')
  }

  useEffect(() => {
    preorderModalRef?.current?.getElementsByClassName("preorder-container-header")[0].focus()
  },[preorderModalRef])

  //CMS API CALL
  const { page: cmsData } = usePagesApiParser(
    getQueries(useLocation().search).useGetHomePageQuery({
      queryParams: useLocation().search,
      localePath: useLocation().pathname.split("/")[1],
      region: useLocation().pathname.split("/")[2],
    })
  );

  const generateServiceAvailableText = (serviceText, address) => {
    if (serviceText?.includes("{address}")) {
      let addressSplit = serviceText.split("{address}")
      if (addressSplit[1].includes("{date}")) {
        const dateFormatted = Intl.DateTimeFormat(baseLink[1], { month: "long", year: "numeric" }).format(dayjs(address.preOrderFirstBookingDate));
        return convertHtmlToReact(
          addressSplit[0] +
          `<strong>${address.streetAddress}</strong>` +
          addressSplit[1].replace("{date}", dateFormatted)
        )
      } else {
        return convertHtmlToReact(
          addressSplit[0] +
          `<strong>${address.streetAddress}</strong>` +
          addressSplit[1]
        )
      }
    }
    return null
  }

  return (
    <>
      <div ref={preorderModalRef} className="preorder-modal-container">
        <div className="preorder-container-header" tabIndex="0">
          {convertHtmlToReact(cmsData?.modal_preorder_header)}
        </div>
          {province !== 'bc' ? (
            <>
              <div className="address-info" tabIndex="0">
                {generateServiceAvailableText(
                  cmsData?.modal_preorder_following_services_available_txt,
                  address
                )}
              </div>
              <div className="packages-container">
                {cmsData?.packages.map((pkg, i) => {
                  return (
                    <div className="package-box-container" key={i}>
                      {((i === 0 && !checkIsXGSPON())
                        || (i === 2 && checkIsXGSPON())) ? (
                        <div className="package-box" tabIndex="0">
                          <div className="box-header">
                            {pkg?.title}
                          </div>
                          <div className="box-body">
                            <span className="Wbold">{pkg?.description}</span>
                            {pkg?.tooltip ? (
                              <ToolTip
                                contentStyles={{left: width > 768 ? 0 : "-11.5rem", top: -15, override: true}}
                                helperStyles={{top: 0, left: 15}}
                                iconStyles={{marginBottom: "0", marginTop: "0"}}
                              >
                                <p>{pkg.tooltip}</p>
                              </ToolTip>
                            ) : null}
                          </div>
                        </div>
                      ) : (
                        <div className="package-box" tabIndex="0">
                          {checkIsXGSPON() ? (
                            <>
                              <div className="box-header">
                                {pkg?.title}
                              </div>
                              <div className="box-body">
                                <span className="Wbold">{pkg?.description}</span>
                                {pkg?.tooltip ? (
                                  <ToolTip
                                    contentStyles={{left: width > 768 ? 0 : "-11.5rem", top: -15, override: true}}
                                    helperStyles={{top: 0, left: 15}}
                                    iconStyles={{marginBottom: "0", marginTop: "0"}}
                                  >
                                    <p>{pkg.tooltip}</p>
                                  </ToolTip>
                                ) : null}
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="box-header coming-soon">
                                {pkg?.coming_soon_txt}
                              </div>
                              <div className="box-body coming-soon">
                                <span className="Wbold">{pkg?.description}</span>
                              </div>
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
              {cmsData?.modal_preorder_tv_home_phone_available_txt ? (
              <div className="extra-services-info">
                <p tabIndex="0">{convertHtmlToReact(cmsData?.modal_preorder_tv_home_phone_available_txt)}</p>
              </div>
              ) : null }
              {cmsData?.modal_preorder_blurb ? (
              <div className="extra-services-info">
                <p tabIndex="0">{convertHtmlToReact(cmsData?.modal_preorder_blurb)}</p>
              </div>
              ) : null }
              <div className="preorder-container">
                <Button click={() => handleStart()}>
                  {cmsData?.modal_preorder_start_order_btn_text}
                </Button>
                <p onClick={() => handleReturn()} className="Wbold preorder-modal-text" tabIndex="0">
                  {cmsData?.modal_preorder_change_address_link_text}
                </p>
              </div>
              {handleShowRegionWarning() ? (
                <div className="preorder-container-footer">
                  {switchRegion()}
                </div>
              ) : null}
            </>
          ) : (
            <>
              <div className="preorder-container">
                <Button click={() => handleStart()}>
                  {cmsData?.modal_preorder_start_order_btn_text}
                </Button>

                <p onClick={() => handleReturn()} className="Wbold preorder-modal-text">
                {cmsData?.modal_preorder_change_address_link_text}
                </p>
              </div>
              {handleShowRegionWarning() ? (
                <div className="preorder-container-footer">
                  {switchRegion()}
                </div>
              ) : null}
            </>
          )}
      </div>
    </>
  );
};

export default PreOrderModal;
