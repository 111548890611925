import * as React from "react";

const SvgComponent = (props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M12 3C13.78 3 15.5201 3.52779 17.0002 4.51672C18.4802 5.50566 19.6338 6.91125 20.3149 8.55579C20.9961 10.2003 21.1744 12.0099 20.8271 13.7557C20.4798 15.5016 19.6226 17.1052 18.364 18.3639C17.1053 19.6226 15.5017 20.4798 13.7559 20.827C12.01 21.1743 10.2004 20.9961 8.55585 20.3149C6.91132 19.6338 5.50572 18.4802 4.51679 17.0001C3.52786 15.5201 3 13.78 3 12C3 9.61305 3.94822 7.32381 5.63605 5.63599C7.32388 3.94816 9.61306 3 12 3ZM12 2C10.0222 2 8.08883 2.58649 6.44434 3.6853C4.79985 4.78412 3.51811 6.34583 2.76123 8.1731C2.00436 10.0004 1.80629 12.0111 2.19214 13.9509C2.578 15.8907 3.53043 17.6725 4.92896 19.071C6.32748 20.4696 8.10933 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9956 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 9.34784 20.9465 6.80432 19.0711 4.92896C17.1957 3.05359 14.6522 2 12 2Z" fill="#00663E"/>
    <path d="M12 3C13.78 3 15.5201 3.52779 17.0002 4.51672C18.4802 5.50566 19.6338 6.91125 20.3149 8.55579C20.9961 10.2003 21.1744 12.0099 20.8271 13.7557C20.4798 15.5016 19.6226 17.1052 18.364 18.3639C17.1053 19.6226 15.5017 20.4798 13.7559 20.827C12.01 21.1743 10.2004 20.9961 8.55585 20.3149C6.91132 19.6338 5.50572 18.4802 4.51679 17.0001C3.52786 15.5201 3 13.78 3 12C3 9.61305 3.94822 7.32381 5.63605 5.63599C7.32388 3.94816 9.61306 3 12 3M12 2C10.0222 2 8.08883 2.58649 6.44434 3.6853C4.79985 4.78412 3.51811 6.34583 2.76123 8.1731C2.00436 10.0004 1.80629 12.0111 2.19214 13.9509C2.578 15.8907 3.53043 17.6725 4.92896 19.071C6.32748 20.4696 8.10933 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9956 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 9.34784 20.9465 6.80432 19.0711 4.92896C17.1957 3.05359 14.6522 2 12 2Z" stroke="#00663E"/>
    <path d="M16.97 15.51C16.8817 15.5106 16.7951 15.4863 16.72 15.4399L11.72 12.4399C11.6469 12.3944 11.5866 12.331 11.5446 12.2559C11.5027 12.1807 11.4804 12.0961 11.48 12.01V8.01001C11.4825 7.8782 11.536 7.75252 11.6292 7.6593C11.7224 7.56608 11.8482 7.51257 11.98 7.51001C12.1126 7.51001 12.2397 7.5626 12.3335 7.65637C12.4273 7.75014 12.48 7.8774 12.48 8.01001V11.72L17.22 14.58C17.2767 14.6139 17.3261 14.6587 17.3654 14.7118C17.4047 14.7649 17.433 14.8254 17.4489 14.8895C17.4647 14.9537 17.4676 15.0204 17.4575 15.0857C17.4474 15.151 17.4244 15.2136 17.39 15.27C17.3453 15.3417 17.2835 15.4011 17.2102 15.443C17.1369 15.4849 17.0544 15.5079 16.97 15.51Z" fill="#00663E" stroke="#00663E"/>
  </svg>

);

export default SvgComponent;
