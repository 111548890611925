import React from "react";
import "pages/PublicWiFi/Public.scss";
import ContactInfo from "components/reusable/ContactInfo/ContactInfo";
import PageLink from "components/reusable/Link/PageLink/PageLink";
import Info from "assets/Icons/Info";
import { useLocation } from "react-router-dom";
import { getQueries } from "api/wagtailAdapter";
import { usePagesApiParser } from "services/hooks/PagesAPIParser";
import { convertHtmlToReact } from "@hedgedoc/html-to-react/dist/convertHtmlToReact";
import InternetServiceOverflowCarousel from "components/Carousel/InternetServiceOverflowCarousel/InternetServiceOverflowCarousel";
import Head from "components/reusable/Head/Head";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";

const APIMessageInfoBox = (info_box) => {
  return {
    headerSmall: info_box.header_small,
    headerLarge: info_box.header_large,
    body: info_box.body,
    link: info_box.link,
    linkText: info_box.link_txt,
    disclaimer: info_box.disclaimer,
  };
};

const PublicCard = ({
  headerSmall,
  headerLarge,
  body,
  link,
  linkText,
  disclaimer,
}) => {
  return (
    <div className="public-card">
      <div>
        <p className="public-card-subtitle">{headerSmall}</p>
        <h2>{headerLarge}</h2>
      </div>

      <p className="public-card-text">{convertHtmlToReact(body)}</p>

      {link && linkText && (
        <PageLink arrow={"true"} to={link}>
          {linkText}
        </PageLink>
      )}

      {disclaimer && (
        <div className="public-card-info">
          <Info />

          <p>{disclaimer}</p>
        </div>
      )}
    </div>
  );
};

const Public = () => {
  const {
    page: info,
    isLoading,
    isError,
    error,
  } = usePagesApiParser(
    getQueries(useLocation().search).useGetPublicWifiPageQuery({
      queryParams: useLocation().search,
      localePath: useLocation().pathname.split("/")[1],
      region: useLocation().pathname.split("/")[2],
    })
  );


  return (
    <div>
      <Head
        title={
          useLocation().pathname.split("/")[1] === "fr" ?
            "Beanfield Public WiFi" :
            "Beanfield Public WiFi"
        }
        description={
          useLocation().pathname.split("/")[1] === "fr" ?
            "Profitez du WiFi illimité public gratuit Beanfield dans un certain nombre croissant d'endroits à travers Toronto. " :
            "Enjoy Beanfield Free Public WiFi at a number of growing locations across Toronto"
        }
        imageName={"PublicWifi"}
        locale={useLocation().pathname.split("/")[1]}
      />
      <div className="resi-container">
        <Breadcrumbs route={"public-wifi"}></Breadcrumbs>
      </div>
      <div className="resi-container">
        <div className="resi-margin-container">
          <div className="public-header">
            <h1>{info?.header_title}</h1>
            <p>{convertHtmlToReact(info?.header_description)}</p>
          </div>
        </div>
      </div>

      <div className="resi-container">
        <div className="resi-margin-container">
          <div className="public-wifi-map"
          >
            <iframe
              src="https://www.google.com/maps/d/u/3/embed?mid=1MYV3k_ZN_jmYz1cARL5_A7v2HfU"
              frameBorder="0"
              scrolling="no"
            />
          </div>
        </div>
      </div>

      <div className="resi-container">
        <div className="resi-margin-container">
          <h2 className="public-subtitle">{info?.body_header}</h2>
          <div className="public-card-container">
            {info?.info_boxes?.map((info_box) =>
              React.createElement(PublicCard, APIMessageInfoBox(info_box))
            )}
          </div>
        </div>
      </div>

      <h2 className="public-subtitle">{info?.locations_header}</h2>
      {info?.locations?.length > 0 && (
        <InternetServiceOverflowCarousel
          items={info?.locations.map(location => {
            return {
              ...location,
              description: location.body,
            }
          })}
        />
      )}

      {info?.contact_us_page?.badges?.length > 0 && (
        <ContactInfo
          title={info.contact_us_page.title}
          description={info.contact_us_page.description}
          badges={info.contact_us_page.badges}
        />
      )}
    </div>
  );
};

export default Public;
