import "pages/Survey/Survey.scss";
import Verbatim from "components/reusable/Verbatim/Verbatim";
import SingleChoice from "components/reusable/SingleChoice/SingleChoice";
import { useDispatch, useSelector } from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import { getQueries } from "api/wagtailAdapter";
import FacebookIcon from "assets/socialIcons/Facebook";
import InstagramIcon from "assets/socialIcons/Instagram";
import LinkedinIcon from "assets/socialIcons/Linkedin";
import XIcon from "assets/socialIcons/X";
import Checkbox from "components/reusable/Checkbox/Checkbox";
import {useEffect, useState} from "react";
import { setAnswer } from "store/reducers/survey/surveySlice";
import { useSubmitSurveyMutation } from "api/beanfield/SurveyPage";
import MainLogo from "assets/svg/MainLogo";
import { toggleModal } from "store/reducers/modal/modalSlice";
import { convertHtmlToReact } from "@hedgedoc/html-to-react/dist/convertHtmlToReact";
import { getError, surveySchema, transformValidationErrors } from "./schema";
import CircularProgress from '@mui/material/CircularProgress';



const Survey = () => {
  const { search, pathname } = useLocation();
  const dispatch = useDispatch();
  const { survey } = useSelector((state) => state.surveySlice);
  const [surveySuccess, setSurveySuccess] = useState(false);
  const navigate = useNavigate();
  const [errors, setErrors] = useState([]);
  const [selected, setSelected] = useState('');
  const [isValid, setIsValid] = useState(false);

  let baseLink = "/" + pathname.split("/")[1] + "/" + pathname.split("/")[2];

  const { data } = getQueries(search).useGetSurveyPageQuery({
      queryParams: search,
      localePath: pathname.split("/")[1],
      region: pathname.split("/")[2],
    });

  const surveyData = data?.items[0];

  const handleChange = event => {
      setSelected(event.target.value.toLowerCase());
      dispatch(setAnswer({ name: "q1",  value: event.target.value }));
  };

  const persistData = () => {
    const urlParams = new URLSearchParams(window.location.search);
    if(!urlParams || urlParams.get('cid') === null) {
        navigate(baseLink);
    }
    if (urlParams) {
        if (urlParams.get('cid') !== null) {
            sessionStorage.setItem('cid', urlParams.get('cid'));
            dispatch(setAnswer({
                cid: urlParams.get('cid'),
            }));
        }
        if (urlParams.get('oid') !== null) {
            sessionStorage.setItem('oid', urlParams.get('oid'));
            dispatch(setAnswer({
                oid: urlParams.get('oid')
            }));
        }
        if (urlParams.get('q1') !== null) {
            setSelected(urlParams.get('q1').toLowerCase());
            sessionStorage.setItem('q1', urlParams.get('q1'));
            dispatch(setAnswer({
                name: "q1",  value: urlParams.get('q1'),
            }));
        }
    }
  };
  useEffect(() => {
    persistData();
  }, []);

  const [
    submitSurvey,
    {
      data: surveySubmitData,
      // error: surveySubmitError,
      isLoading: submitSurveyLoading,
    },
  ] = useSubmitSurveyMutation();



  const handleSubmit = async () => {
    try {
        await surveySchema.validate(survey,  { abortEarly: false });
    }
    catch (e) {
        await setErrors(transformValidationErrors(e));
        return;
    }
    submitSurvey(survey);

  };
  useEffect(() => {
    if (surveySubmitData?.success) {
      setSurveySuccess(true);
    }
  }, [surveySubmitData]);

  useEffect( () => {
        surveySchema.isValid(survey).then((res) => setIsValid(res))
  } );


  const modalContent = () => {
    return (
      <div className="survey_warning_container">
          <div className="modal-header">
              <h2>{surveyData?.exit_modal_header}</h2>
          </div>
          <div className="modal-content">
              <div>{convertHtmlToReact(surveyData?.exit_modal_text)}</div>
          </div>
          <div className="modal-footer">
              <button className="go-back-btn" onClick={() => dispatch(toggleModal())}>
                  {surveyData?.exit_modal_go_back_btn_text}
              </button>
              <button className="exit-btn Wbold" onClick={() => { dispatch(toggleModal()); navigate(baseLink);}}>
                  {surveyData?.exit_modal_exit_btn_text}
              </button>
          </div>
      </div>
    );
  };


  useEffect(() => {
    const handleBeforeUnload = (e) => {
      e.preventDefault();
      e.returnValue = "";
    };
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect( () => {
      // reset the other value when q8 change
      dispatch(setAnswer({name: 'q8_other', value: ""}))
  }, [survey.q8]);
  return (
    <div>
      <div className="resi-container">
        <div className="resi-margin-container">
          <div className="survey-navbar-container">
                <div className="survey-navbar-sub-container">
                  <div
                    className="survey-navbar-logo-container"
                     onClick={() => dispatch(toggleModal({ children: modalContent() }))}
                  >
                    <MainLogo />
                  </div>
                </div>
            </div>
          <div className="survey-header">
            <div className="survey-header-wrapper">
              <div className="survey-header-content">
                <h1>{surveyData?.page_header}</h1>
              </div>
            </div>
          </div>
          <div className="survey-content">
              { !surveySuccess ?
                  <div className="survey-wrapper">
                      <div className="survey-content-header">
                          <h2>{surveyData?.survey_header}</h2>
                      </div>
                      <div className="survey-content-main">
                          <div className="survey-content-blurb">
                              {convertHtmlToReact(surveyData?.survey_intro)}
                          </div>
                          <div className="survey">
                              <SingleChoice
                                  question={surveyData?.q1_text}
                                  error={getError(surveyData?.q1_name, errors)}
                                  name={surveyData?.q1_name}
                                  children={
                                      <>
                                          <div className="radio_btn_wrapper">
                                              <input
                                                  type="radio"
                                                  id={surveyData?.q1_name + "1"}
                                                  name={surveyData?.q1_name}
                                                  value={surveyData?.q1_answer1}
                                                  checked={selected === 'yes'}
                                                  onChange={handleChange}
                                              />
                                              <label htmlFor={surveyData?.q1_name + "1"}>
                                                  {surveyData?.q1_answer1}
                                              </label>
                                          </div>
                                          <div className="radio_btn_wrapper">
                                              <input
                                                  type="radio"
                                                  id={surveyData?.q1_name + "2"}
                                                  name={surveyData?.q1_name}
                                                  value={surveyData?.q1_answer2}
                                                  checked={selected === 'no'}
                                                  onChange={handleChange}
                                              />
                                              <label htmlFor={surveyData?.q1_name + "2"}>
                                                  {surveyData?.q1_answer2}
                                              </label>
                                          </div>
                                      </>
                                  }
                              />
                              {selected === "no" ?
                                  <SingleChoice
                                      question={surveyData?.q1a_text}
                                      error={getError(surveyData?.q1a_name, errors)}
                                      name={surveyData?.q1a_name}
                                      classes="qnormal"
                                      children={
                                          <>
                                              <div className="radio_btn_wrapper">
                                                  <input
                                                      type="radio"
                                                      id={surveyData?.q1a_name + "1"}
                                                      name={surveyData?.q1a_name}
                                                      value={surveyData?.q1a_answer1}
                                                      onClick={(e) => dispatch(setAnswer({
                                                          name: 'q1a',
                                                          value: e.target.value
                                                      }))}
                                                  />
                                                  <label htmlFor={surveyData?.q1a_name + "1"}>
                                                      {surveyData?.q1a_answer1}
                                                  </label>
                                              </div>
                                              <div className="radio_btn_wrapper">
                                                  <input
                                                      type="radio"
                                                      id={surveyData?.q1a_name + "2"}
                                                      name={surveyData?.q1a_name}
                                                      value={surveyData?.q1a_answer2}
                                                      onClick={(e) => dispatch(setAnswer({
                                                          name: 'q1a',
                                                          value: e.target.value
                                                      }))}
                                                  />
                                                  <label htmlFor={surveyData?.q1a_name + "2"}>
                                                      {surveyData?.q1a_answer2}
                                                  </label>
                                              </div>
                                          </>
                                      }
                                  />
                                  : null}
                              <SingleChoice
                                  question={surveyData?.q2_text}
                                  children={
                                      <>
                                          <div className="radio_btn_wrapper">
                                              <input
                                                  type="radio"
                                                  id={surveyData?.q2_name + "1"}
                                                  name={surveyData?.q2_name}
                                                  value={surveyData?.q2_answer1}
                                                  onClick={(e) => dispatch(setAnswer({
                                                      name: 'q2',
                                                      value: e.target.value
                                                  }))}
                                              />
                                              <label htmlFor={surveyData?.q2_name + "1"}>
                                                  {surveyData?.q2_answer1}
                                              </label>
                                          </div>
                                          <div className="radio_btn_wrapper">
                                              <input
                                                  type="radio"
                                                  id={surveyData?.q2_name + "2"}
                                                  name={surveyData?.q2_name}
                                                  value={surveyData?.q2_answer2}
                                                  onClick={(e) => dispatch(setAnswer({
                                                      name: 'q2',
                                                      value: e.target.value
                                                  }))}
                                              />
                                              <label htmlFor={surveyData?.q2_name + "2"}>
                                                  {surveyData?.q2_answer2}
                                              </label>
                                          </div>
                                      </>
                                  }
                              />
                              {survey.q2 === "No" ?
                                  <Verbatim
                                      question={surveyData?.q2a_text}
                                      placeholder={surveyData?.placeholder_text}
                                      callback={(e) => dispatch(setAnswer({name: 'q2a', value: e.target.value}))}
                                      classes="qnormal"
                                  />
                                  : null}
                              <SingleChoice
                                  question={surveyData?.q3_text}
                                  error={getError(surveyData?.q3_name, errors)}
                                  name={surveyData?.q3_name}
                                  children={
                                      <>
                                          <div className="radio_btn_wrapper">
                                              <input
                                                  type="radio"
                                                  id={surveyData?.q3_name + "1"}
                                                  name={surveyData?.q3_name}
                                                  value={surveyData?.q3_answer1}
                                                  onClick={(e) => dispatch(setAnswer({
                                                      name: 'q3',
                                                      value: e.target.value
                                                  }))}
                                              />
                                              <label htmlFor={surveyData?.q3_name + "1"}>
                                                  {surveyData?.q3_answer1}
                                              </label>
                                          </div>
                                          <div className="radio_btn_wrapper">
                                              <input
                                                  type="radio"
                                                  id={surveyData?.q3_name + "2"}
                                                  name={surveyData?.q3_name}
                                                  value={surveyData?.q3_answer2}
                                                  onClick={(e) => dispatch(setAnswer({
                                                      name: 'q3',
                                                      value: e.target.value
                                                  }))}
                                              />
                                              <label htmlFor={surveyData?.q3_name + "2"}>
                                                  {surveyData?.q3_answer2}
                                              </label>
                                          </div>
                                          <div className="radio_btn_wrapper">
                                              <input
                                                  type="radio"
                                                  id={surveyData?.q3_name + "3"}
                                                  name={surveyData?.q3_name}
                                                  value={surveyData?.q3_answer3}
                                                  onClick={(e) => dispatch(setAnswer({
                                                      name: 'q3',
                                                      value: e.target.value
                                                  }))}
                                              />
                                              <label htmlFor={surveyData?.q3_name + "3"}>
                                                  {surveyData?.q3_answer3}
                                              </label>
                                          </div>
                                      </>
                                  }
                              />
                              <SingleChoice
                                  question={surveyData?.q4_text}
                                  error={getError(surveyData?.q4_name, errors)}
                                  name={surveyData?.q4_name}
                                  children={
                                      <>
                                          <div className="radio_btn_wrapper">
                                              <input
                                                  type="radio"
                                                  id={surveyData?.q4_name + "1"}
                                                  name={surveyData?.q4_name}
                                                  value={surveyData?.q4_answer1}
                                                  onClick={(e) => dispatch(setAnswer({
                                                      name: 'q4',
                                                      value: e.target.value
                                                  }))}
                                              />
                                              <label htmlFor={surveyData?.q4_name + "1"}>
                                                  {surveyData?.q4_answer1}
                                              </label>
                                          </div>
                                          <div className="radio_btn_wrapper">
                                              <input
                                                  type="radio"
                                                  id={surveyData?.q4_name + "2"}
                                                  name={surveyData?.q4_name}
                                                  value={surveyData?.q4_answer2}
                                                  onClick={(e) => dispatch(setAnswer({
                                                      name: 'q4',
                                                      value: e.target.value
                                                  }))}
                                              />
                                              <label htmlFor={surveyData?.q4_name + "2"}>
                                                  {surveyData?.q4_answer2}
                                              </label>
                                          </div>
                                          <div className="radio_btn_wrapper">
                                              <input
                                                  type="radio"
                                                  id={surveyData?.q4_name + "3"}
                                                  name={surveyData?.q4_name}
                                                  value={surveyData?.q4_answer3}
                                                  onClick={(e) => dispatch(setAnswer({
                                                      name: 'q4',
                                                      value: e.target.value
                                                  }))}
                                              />
                                              <label htmlFor={surveyData?.q4_name + "3"}>
                                                  {surveyData?.q4_answer3}
                                              </label>
                                          </div>
                                      </>
                                  }
                              />
                              <SingleChoice
                                  question={surveyData?.q5_text}
                                  error={getError(surveyData?.q5_name, errors)}
                                  name={surveyData?.q5_name}
                                  children={
                                      <>
                                          <div className="radio_btn_wrapper">
                                              <input
                                                  type="radio"
                                                  id={surveyData?.q5_name + "1"}
                                                  name={surveyData?.q5_name}
                                                  value={surveyData?.q5_answer1}
                                                  onClick={(e) => dispatch(setAnswer({
                                                      name: 'q5',
                                                      value: e.target.value
                                                  }))}
                                              />
                                              <label htmlFor={surveyData?.q5_name + "1"}>
                                                  {surveyData?.q5_answer1}
                                              </label>
                                          </div>
                                          <div className="radio_btn_wrapper">
                                              <input
                                                  type="radio"
                                                  id={surveyData?.q5_name + "2"}
                                                  name={surveyData?.q5_name}
                                                  value={surveyData?.q5_answer2}
                                                  onClick={(e) => dispatch(setAnswer({
                                                      name: 'q5',
                                                      value: e.target.value
                                                  }))}
                                              />
                                              <label htmlFor={surveyData?.q5_name + "2"}>
                                                  {surveyData?.q5_answer2}
                                              </label>
                                          </div>
                                      </>
                                  }
                              />
                              <SingleChoice
                                  question={surveyData?.q6_text}
                                  children={
                                      <>
                                          <div className="radio_btn_wrapper">
                                              <input
                                                  type="radio"
                                                  id={surveyData?.q6_name + "1"}
                                                  name={surveyData?.q6_name}
                                                  value={surveyData?.q6_answer1}
                                                  onClick={(e) => dispatch(setAnswer({
                                                      name: 'q6',
                                                      value: e.target.value
                                                  }))}
                                              />
                                              <label htmlFor={surveyData?.q6_name + "1"}>
                                                  {surveyData?.q6_answer1}
                                              </label>
                                          </div>
                                          <div className="radio_btn_wrapper">
                                              <input
                                                  type="radio"
                                                  id={surveyData?.q6_name + "2"}
                                                  name={surveyData?.q6_name}
                                                  value={surveyData?.q6_answer2}
                                                  onClick={(e) => dispatch(setAnswer({
                                                      name: 'q6',
                                                      value: e.target.value
                                                  }))}
                                              />
                                              <label htmlFor={surveyData?.q6_name + "2"}>
                                                  {surveyData?.q6_answer2}
                                              </label>
                                          </div>
                                      </>
                                  }
                              />
                              <Verbatim
                                  question={surveyData?.q7_text}
                                  error={getError(surveyData?.q7_name, errors)}
                                  name={surveyData?.q7_name}
                                  placeholder={surveyData?.placeholder_text}
                                  callback={(e) => dispatch(setAnswer({name: 'q7', value: e.target.value}))}
                              />
                              <SingleChoice
                                  question={convertHtmlToReact(surveyData?.q7a_text)}
                                  error={getError(surveyData?.q7a_name, errors)}
                                  name={surveyData?.q7a_name}
                                  classes="qnormal"
                                  children={
                                      <>
                                          <div className="radio_btn_wrapper">
                                              <input
                                                  type="radio"
                                                  id={surveyData?.q7a_name + "1"}
                                                  name={surveyData?.q7a_name}
                                                  value={surveyData?.q7a_answer1}
                                                  onClick={(e) => dispatch(setAnswer({
                                                      name: 'q7a',
                                                      value: e.target.value
                                                  }))}
                                              />
                                              <label htmlFor={surveyData?.q7a_name + "1"}>
                                                  {surveyData?.q7a_answer1}
                                              </label>
                                          </div>
                                          <div className="radio_btn_wrapper">
                                              <input
                                                  type="radio"
                                                  id={surveyData?.q7a_name + "2"}
                                                  name={surveyData?.q7a_name}
                                                  value={surveyData?.q7a_answer2}
                                                  onClick={(e) => dispatch(setAnswer({
                                                      name: 'q7a',
                                                      value: e.target.value
                                                  }))}
                                              />
                                              <label htmlFor={surveyData?.q7a_name + "2"}>
                                                  {surveyData?.q7a_answer2}
                                              </label>
                                          </div>
                                      </>
                                  }
                              />
                              <SingleChoice
                                  question={surveyData?.q8_text}
                                  error={getError(surveyData?.q8_name, errors)}
                                  name={surveyData?.q8_name}
                                  children={
                                      <>
                                          <div className="radio_btn_wrapper">
                                              <input
                                                  type="radio"
                                                  id={surveyData?.q8_name + "1"}
                                                  name={surveyData?.q8_name}
                                                  value={surveyData?.q8_answer1}
                                                  onClick={(e) => dispatch(setAnswer({
                                                      name: 'q8',
                                                      value: e.target.value
                                                  }))}
                                              />
                                              <label htmlFor={surveyData?.q8_name + "1"}>
                                                  {surveyData?.q8_answer1}
                                              </label>
                                          </div>
                                          <div className="radio_btn_wrapper">
                                              <input
                                                  type="radio"
                                                  id={surveyData?.q8_name + "2"}
                                                  name={surveyData?.q8_name}
                                                  value={surveyData?.q8_answer2}
                                                  onClick={(e) => dispatch(setAnswer({
                                                      name: 'q8',
                                                      value: e.target.value
                                                  }))}
                                              />
                                              <label htmlFor={surveyData?.q8_name + "2"}>
                                                  {surveyData?.q8_answer2}
                                              </label>
                                          </div>
                                          <div className="radio_btn_wrapper">
                                              <input
                                                  type="radio"
                                                  id={surveyData?.q8_name + "3"}
                                                  name={surveyData?.q8_name}
                                                  value={surveyData?.q8_answer3}
                                                  onClick={(e) => dispatch(setAnswer({
                                                      name: 'q8',
                                                      value: e.target.value
                                                  }))}
                                              />
                                              <label htmlFor={surveyData?.q8_name + "3"}>
                                                  {surveyData?.q8_answer3}
                                              </label>
                                          </div>
                                          <div className="radio_btn_wrapper">
                                              <input
                                                  type="radio"
                                                  id={surveyData?.q8_name + "4"}
                                                  name={surveyData?.q8_name}
                                                  value={surveyData?.q8_answer4}
                                                  onClick={(e) => dispatch(setAnswer({
                                                      name: 'q8',
                                                      value: e.target.value
                                                  }))}
                                              />
                                              <label htmlFor={surveyData?.q8_name + "4"}>
                                                  {surveyData?.q8_answer4}
                                              </label>
                                          </div>
                                          <div className="radio_btn_wrapper">
                                              <input
                                                  type="radio"
                                                  id={surveyData?.q8_name + "5"}
                                                  name={surveyData?.q8_name}
                                                  value={surveyData?.q8_answer5}
                                                  onClick={(e) => dispatch(setAnswer({
                                                      name: 'q8',
                                                      value: e.target.value
                                                  }))}
                                              />
                                              <label htmlFor={surveyData?.q8_name + "5"}>
                                                  {surveyData?.q8_answer5}
                                              </label>
                                          </div>
                                          <div className="radio_btn_wrapper">
                                              <input
                                                  type="radio"
                                                  id={surveyData?.q8_name + "6"}
                                                  name={surveyData?.q8_name}
                                                  value={surveyData?.q8_answer6}
                                                  onClick={(e) => dispatch(setAnswer({
                                                      name: 'q8',
                                                      value: e.target.value
                                                  }))}
                                              />
                                              <label htmlFor={surveyData?.q8_name + "6"}>
                                                  {surveyData?.q8_answer6}
                                              </label>
                                          </div>
                                          {survey?.q8 === 'Other'?
                                              <div className="q8_other">
                                                  <label htmlFor={surveyData?.q8_other_text}>
                                                      {surveyData?.q8_other_text}:
                                                  </label>
                                                  <input
                                                      type="text"
                                                      id={surveyData?.q8_other_name}
                                                      name={surveyData?.q8_other_name}
                                                      onChange={(e) => dispatch(setAnswer({
                                                          name: 'q8_other',
                                                          value: e.target.value
                                                      }))}
                                                  />

                                              </div>
                                          : null }
                                      </>
                                  }
                              />
                              <SingleChoice
                                  question={surveyData?.q9_text}
                                  error={getError(surveyData?.q9_name, errors)}
                                  name={surveyData?.q9_name}
                                  children={
                                      <>
                                          <div className="radio_btn_wrapper">
                                              <input
                                                  type="radio"
                                                  id={surveyData?.q9_name + "1"}
                                                  name={surveyData?.q9_name}
                                                  value={surveyData?.q9_answer1}
                                                  onClick={(e) => dispatch(setAnswer({
                                                      name: 'q9',
                                                      value: e.target.value
                                                  }))}
                                              />
                                              <label htmlFor={surveyData?.q9_name + "1"}>
                                                  {surveyData?.q9_answer1}
                                              </label>
                                          </div>
                                          <div className="radio_btn_wrapper">
                                              <input
                                                  type="radio"
                                                  id={surveyData?.q9_name + "2"}
                                                  name={surveyData?.q9_name}
                                                  value={surveyData?.q9_answer2}
                                                  onClick={(e) => dispatch(setAnswer({
                                                      name: 'q9',
                                                      value: e.target.value
                                                  }))}
                                              />
                                              <label htmlFor={surveyData?.q9_name + "2"}>
                                                  {surveyData?.q9_answer2}
                                              </label>
                                          </div>
                                          <div className="radio_btn_wrapper">
                                              <input
                                                  type="radio"
                                                  id={surveyData?.q9_name + "3"}
                                                  name={surveyData?.q9_name}
                                                  value={surveyData?.q9_answer3}
                                                  onClick={(e) => dispatch(setAnswer({
                                                      name: 'q9',
                                                      value: e.target.value
                                                  }))}
                                              />
                                              <label htmlFor={surveyData?.q9_name + "3"}>
                                                  {surveyData?.q9_answer3}
                                              </label>
                                          </div>
                                          <div className="radio_btn_wrapper">
                                              <input
                                                  type="radio"
                                                  id={surveyData?.q9_name + "4"}
                                                  name={surveyData?.q9_name}
                                                  value={surveyData?.q9_answer4}
                                                  onClick={(e) => dispatch(setAnswer({
                                                      name: 'q9',
                                                      value: e.target.value
                                                  }))}
                                              />
                                              <label htmlFor={surveyData?.q9_name + "4"}>
                                                  {surveyData?.q9_answer4}
                                              </label>
                                          </div>
                                          <div className="radio_btn_wrapper">
                                              <input
                                                  type="radio"
                                                  id={surveyData?.q9_name + "5"}
                                                  name={surveyData?.q9_name}
                                                  value={surveyData?.q9_answer5}
                                                  onClick={(e) => dispatch(setAnswer({
                                                      name: 'q9',
                                                      value: e.target.value
                                                  }))}
                                              />
                                              <label htmlFor={surveyData?.q9_name + "5"}>
                                                  {surveyData?.q9_answer5}
                                              </label>
                                          </div>
                                      </>
                                  }
                              />
                          </div>
                          <div className="follow-up">
                              <div><strong>{data?.items[0].followup_consent_header}</strong></div>
                              <div className="consent"><Checkbox onClick={(e) => {
                                  dispatch(setAnswer({
                                      name: "followup_consent",
                                      value: e
                                  }))
                              }}/> <span>{data?.items[0].followup_consent_text}</span></div>
                          </div>
                          <div className="submit-responses">
                              <div className="submit-btn">
                                  <button className="btn" onClick={handleSubmit} disabled={!isValid || submitSurveyLoading }>
                                      {submitSurveyLoading? <CircularProgress  size={16} color={"inherit"}/> : "Submit Feedback"}
                                  </button>
                              </div>
                          </div>
                      </div>
                  </div>:
                  <div className="survey-success-wrapper">
                      <div className="survey-success-header">
                          <h2>{surveyData?.thank_you_header}</h2>
                      </div>
                      <div className="survey-success-content">
                          {convertHtmlToReact(surveyData?.thank_you_text)}
                      </div>
                      <div className="survey-success-social">
                          <p>Stay connected with us</p>
                          <div className="footer-social-container">
                              <a
                                  target="_blank"
                                  rel="noreferrer"
                                  href="https://twitter.com/beanfieldcanada"
                                  className="social-icon"
                              >
                                  <XIcon/>
                              </a>
                              <a
                                  target="_blank"
                                  rel="noreferrer"
                                  href="https://www.facebook.com/beanfieldcanada"
                                  className="social-icon"
                              >
                                  <FacebookIcon/>
                              </a>
                              <a
                                  target="_blank"
                                  rel="noreferrer"
                                  href="https://www.instagram.com/beanfieldcanada/"
                                  className="social-icon"
                              >
                                  <InstagramIcon/>
                              </a>
                              <a
                                  target="_blank"
                                  rel="noreferrer"
                                  href="https://www.linkedin.com/company/beanfieldcanada/"
                                  className="social-icon"
                              >
                                  <LinkedinIcon/>
                              </a>
                          </div>
                      </div>
                  </div>
              }
          </div>
        </div>
      </div>
    </div>
  );
};

export default Survey;
