import * as React from "react";

/**
 *
 * @param {string} color right,left,up,down to render corresponding arrows
 * @param {string} turn right,left,up,down to render corresponding arrows
 * @returns
 */

const SvgComponent = (props) => {
  let turn;
  switch (props.turn) {
    case "right":
      turn = 0;
      break;
    case "left":
      turn = 0.5;
      break;
    case "up":
      turn = -0.25;
      break;
    case "down":
      turn = 0.25;
      break;
    default:
      turn = 0;
  }

  return (
    <div
      style={{
        transform: `rotate(${turn}turn)`,
        height: props.height,
        ...props.containerStyle,
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        {...props}
      >
        <path
          d="M13.2922 12.0002L9.04622 7.75397C8.95256 7.66047 8.90256 7.54572 8.89622 7.40972C8.88972 7.27389 8.93972 7.1528 9.04622 7.04647C9.15256 6.93997 9.27047 6.88672 9.39997 6.88672C9.52947 6.88672 9.64739 6.93997 9.75372 7.04647L14.1422 11.4347C14.2294 11.5221 14.2906 11.6112 14.326 11.7022C14.3611 11.7932 14.3787 11.8926 14.3787 12.0002C14.3787 12.1079 14.3611 12.2072 14.326 12.2982C14.2906 12.3892 14.2294 12.4784 14.1422 12.5657L9.75372 16.954C9.66022 17.0476 9.54548 17.0976 9.40947 17.104C9.27364 17.1105 9.15256 17.0605 9.04622 16.954C8.93972 16.8476 8.88647 16.7297 8.88647 16.6002C8.88647 16.4707 8.93972 16.3528 9.04622 16.2465L13.2922 12.0002Z"
          fill={props.color}/>
      </svg>
    </div>
  );
};

export default SvgComponent;
