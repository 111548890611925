import React, { useEffect, useRef, useState } from "react";
import "components/Carousel/HeaderCarousel/Carousel.scss";
import Slider from "react-slick";
import Left from "assets/Carousel/Left";
import Right from "assets/Carousel/Right";
import ActiveDot from "assets/Carousel/ActiveDot";
import InactiveDot from "assets/Carousel/InactiveDot";
import Button from "components/reusable/Button/Button";
import convertHtmlToReact from "@hedgedoc/html-to-react";
import {processWagtailImage, WagtailImage} from "components/Wagtail/WagtailImage";
import { useDispatch } from "react-redux";
import { toggleModal } from "store/reducers/modal/modalSlice";
import SuccessModal from "components/Modals/Success/Success";
import BulkBuildingModal from "components/Modals/BulkBuilding/BulkBuilding";
import FormModal from "components/Modals/Form/Form";
import SelectModal from "components/Modals/Select/Select";
import QuestionnaireModal from "components/Modals/Questionnaire/Questionnaire";
import SpecialOfferModal from "components/Modals/SpecialOffer/SpecialOffer";
import CommercialModal from "components/Modals/Commercial/Commercial";
import FibrestreamModal from "components/Modals/Fibrestream/Fibrestream";
import OneGbModal from "components/Modals/OneGb/OneGb";
import SpecialDeliveryUnitModal from "components/Modals/SpecialDeliveryUnit/SpecialDeliveryUnit";
import OldResidentialModal from "components/Modals/OldResidential/OldResidential";
import { resetEntireOrderSlice } from "store/reducers/order/orderSlice";
import { useResetStateAndSetAddress } from "services/hooks/useResetStateAndSetAddress";
import { useLazyGetSearchQuery, useRateLimitVerifyMutation } from "api/beanfield/Search";
import { MediaContextProvider } from "utils/MediaQueries/Media";
import { sendGoogleTagEvent } from "services/hooks/GoogleTags";
import ReCAPTCHA from "react-google-recaptcha";
import PreOrderModal from "components/Modals/PreOrder/PreOrder";
import dayjs from "dayjs";

const Slide = (props) => {
  return <div>{props.children}</div>;
};

const WelcomeBanner = ({
  banner_image_ras,
  banner_image_svg,
  use_black_friday_promo_layout,
  header,
  subtitle,
  address_placeholder,
  unit_placeholder,
  submit_btn_text,
  inline_empty_address_error,
  inline_empty_unit_error,
  no_matching_addresses_error,
  asterisk_text_for_promo,
  banner_image_mobile_black_friday_left,
  banner_image_mobile_black_friday_right,
}) => {
  const dispatch = useDispatch();
  const addressDropDownRef = useRef();
  const [addressText, setAddressText] = useState("");
  const [address, setAddress] = useState("");
  const [unit, setUnit] = useState("");
  const [addressError, setAddressError] = useState(false);
  const [unitError, setUnitError] = useState(false);
  const [searchClick, setSearchClick] = useState(false);
  const [getSearch, { data, isError }] = useLazyGetSearchQuery({
    address: address,
  });
  const recaptchaRef = React.createRef();
  const width =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;

  const [
    rateLimitVerify,
    {
      data: verificationResponse,
      error: verificationError,
    },
  ] = useRateLimitVerifyMutation();

  const { resetStateAndSetAddress } = useResetStateAndSetAddress();

  const handleSubmit = () => {
    if (address === "" || unit === "") {
      let addErr = address === "";
      let unitErr = unit === "";
      setAddressError(addErr);
      setUnitError(unitErr);
    } else {
      const info = address;

      if (info.unserviceableUnits.includes(unit)) {
        dispatch(toggleModal({ children: <SpecialOfferModal error /> }));
      } else if (info.specialDeliveryUnits.includes(unit)) {
        dispatch(toggleModal({ children: <SpecialDeliveryUnitModal />}));
      }  else {
        if (info.isSpecialBuilding) {
          dispatch(
            toggleModal({
              children: <SpecialOfferModal buildingName={info.specialBuildingName}/>,
            })
          );
          sendGoogleTagEvent({ eventName: 'Specific Building', address: address });
        } else if (info.isOnNet && info.isFibrestream) {
          dispatch(toggleModal({ children: <FibrestreamModal /> }));
        } else if (info.isOnNet && info.isOldResidential) {
          dispatch(toggleModal({ children: <OldResidentialModal /> }));
        } else if (info.isOnNet && !info.isResidential) {
          dispatch(toggleModal({ children: <CommercialModal /> }));
        } else if (info.isOnNet && info.isOneGbps) {
          dispatch(toggleModal({ children: <OneGbModal province={info.province.toLowerCase()} /> }));
          dispatch(resetEntireOrderSlice());
          /** Resetting the order slice to clear previous values
           * Setting user address and unit for the new order process
           * */
          resetStateAndSetAddress({ ...info, unit });
          sendGoogleTagEvent({ eventName: 'On-Net', address: address });
        } else if (info.isOnNet && info.isBulkBuilding) {
          dispatch(toggleModal({ children: <BulkBuildingModal province={info.province.toLowerCase()} address={address} /> }));
          resetStateAndSetAddress({ ...info, unit });
          sendGoogleTagEvent({ eventName: "On-Net", address: address });
        } else if (info.isOnNet) {
          dispatch(toggleModal({ children: <SuccessModal province={info?.province?.toLowerCase()} address={address} /> }));
          dispatch(resetEntireOrderSlice());
          /** Resetting the order slice to clear previous values
           * Setting user address and unit for the new order process
           * */
          resetStateAndSetAddress({ ...info, unit });
          sendGoogleTagEvent({ eventName: 'On-Net', address: address });
        } else {
          if (info.status === "Fibre Build In Progress") {
            if (info.isPreorder && info.onNetDate && (dayjs().isBefore(info.onNetDate))) {
              dispatch(
                toggleModal({
                  children: <PreOrderModal province={info.province.toLowerCase()} address={address}/>,
                })
              );
              resetStateAndSetAddress({ ...info, unit });
            } else {
              dispatch(
                toggleModal({
                  children: <FormModal pending editAddress={address} unit={unit}/>,
                })
              );
            }
            sendGoogleTagEvent({ eventName: "fbip", address: address });
          } else if (info.status === "Interested") {
            dispatch(
              toggleModal({
                children: <SelectModal address={address} unit={unit}/>,
              })
            );
            sendGoogleTagEvent({ eventName: 'Requested', address: address });
          } else if (info.status === "Currently Not On-Net") {
            dispatch(
              toggleModal({
                children: <FormModal editAddress={address} unit={unit}/>,
              })
            );
            sendGoogleTagEvent({ eventName: 'Not On-Net', address: address });
          } else {

            dispatch(
              toggleModal({
                children: <QuestionnaireModal address={address} unit={unit}/>,
              })
            );
            sendGoogleTagEvent({ eventName: "Not On-Net", address: address });
          }
        }
      }
    }
  };

  const handleDropdown = (value) => {
    setSearchClick(true);
    setAddress(value);
    setAddressText(value.addressText)
  };

  const handleAddressInput = (value) => {
    setSearchClick(false);
    setAddressText(value.target.value);
  };

  const handleFocusDropdownList = (e) => {
    if (addressText && e.key === "ArrowDown") {
      e.preventDefault();
      addressDropDownRef.current.getElementsByTagName("p")[0].focus()
    }
  }

  const handlePKeyDown = (e, item, index) => {
    const resultsLength = addressDropDownRef.current.getElementsByTagName("p").length;
    if (e.key === "ArrowUp") {
      e.view.event.preventDefault();
      if (index === 0) {
        addressDropDownRef.current.getElementsByTagName("p")[resultsLength - 1].focus()
      } else {
        addressDropDownRef.current.getElementsByTagName("p")[index - 1].focus()
      }
    }
    if (e.key === "ArrowDown") {
      e.view.event.preventDefault();
      if (index === resultsLength - 1) {
        addressDropDownRef.current.getElementsByTagName("p")[0].focus()
      } else {
        addressDropDownRef.current.getElementsByTagName("p")[index + 1].focus()
      }
    }
    if (e.key === "Enter") {
      handleDropdown(item);
    }
  }

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      if(addressText) {
        getSearch({ address: addressText });
      }
    }, 500);
    return () => clearTimeout(debounceTimeout);
  }, [addressText]);

  useEffect(() => {
    if(data) {
      const res = data.at(0)
      if(res.error) {
        recaptchaRef?.current?.executeAsync().then(value => {
          rateLimitVerify({token: value})
          if (verificationResponse?.success) {
            recaptchaRef?.current?.reset();
            if(addressText) {
              getSearch({ address: addressText });
            }
          }
        });
      }
    }
  }, [data]);

  const modalContent = () => {
    return (
      <>
        <div tabIndex="0">
          <h1 className="carousel-header-title">{convertHtmlToReact(header)}</h1>

          <div className="carousel-header-subtitle">
            {convertHtmlToReact(subtitle)}
          </div>
        </div>


        <div className="carousel-header-input-container">
          <div className="carousel-header-error-container">
            <input
              className={`carousel-header-address-input ${addressError ? 'error' : ''}`}
              placeholder={address_placeholder}
              value={addressText}
              onChange={(e) => handleAddressInput(e)}
              onKeyDown={(e) => handleFocusDropdownList(e)}
            />

            {!addressError ? null : (
              <p className="carousel-header-error-text">
                { inline_empty_address_error }
              </p>
            )}
          </div>

          <div ref={addressDropDownRef} className="carousel-header-dropdown">
            {isError ? (
              <p>
                { no_matching_addresses_error }
              </p>
            ) : searchClick ? null : (
              data?.map((item, index) => {
                return (
                  <p
                    key={index}
                    onClick={() => handleDropdown(item)}
                    onKeyDown={(e) => handlePKeyDown(e, item, index)}
                    tabIndex="0"
                  >
                    {item.addressText}
                  </p>
                );
              })
            )}
          </div>

          <div className="carousel-header-error-container">
            <input
              className={`carousel-header-unit-input ${unitError ? 'error' : ''}`}
              placeholder={unit_placeholder}
              value={unit}
              onChange={(e) => setUnit(e.target.value)}
            />

            {!unitError ? null : (
              <p className="carousel-header-error-text">{ inline_empty_unit_error }</p>
            )}
          </div>
        </div>

        <Button click={() => handleSubmit()}>{submit_btn_text}</Button>
        <div className="asterisk-text">{convertHtmlToReact(asterisk_text_for_promo)}</div>
      </>
    );
  };

  return (
    <div className={use_black_friday_promo_layout ? "resi-container home-banner black-friday-promo" : "resi-container home-banner" } style={{
      backgroundImage: `url(${processWagtailImage(
        banner_image_ras || banner_image_svg
      )})`,
    }}>
      <div className="resi-margin-container">
        <div className="slide-container">
          {(use_black_friday_promo_layout) ? (
            <>
              <div className="mobile-promo-image">
                <div className="offset-border"></div>
                <div className="left-image">
                  <WagtailImage image={banner_image_mobile_black_friday_left}/>
                </div>
                <div className="right-image">
                  <WagtailImage image={banner_image_mobile_black_friday_right}/>
                </div>
              </div>
            </>
          ) : null}
          {modalContent()}
        </div>
      </div>
      {navigator.userAgent !== 'ReactSnap' ? (
        <ReCAPTCHA
          ref={recaptchaRef}
          size="invisible"
          sitekey={process.env.REACT_APP_LIMIT_KEY}
        />
      ) : null }
    </div>
  );
};

const slideMappings = {
  welcome_banner: WelcomeBanner,
};

const Carousel = ({ items }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: false,
    nextArrow: (
      <div>
        <Right />
      </div>
    ),
    prevArrow: (
      <div>
        <Left />
      </div>
    ),
    appendDots: (dots) => (
      <div
        style={{
          position: "relative",
          top: -30,
        }}
      >
        {dots.map((item, index) => {
          if (item.props.className === "slick-active") {
            return <ActiveDot key={index} />;
          } else {
            return <InactiveDot key={index} />;
          }
        })}
      </div>
    ),
  };

  return (
    <MediaContextProvider>
      <div className="carousel-container">
        <Slider {...settings}>
          {items &&
            items.map((item, index) => {
              return (
                <Slide key={index}>
                  {React.createElement(slideMappings[item.type], {
                    ...item?.value,
                  })}
                </Slide>
              );
            })}
        </Slider>
      </div>
    </MediaContextProvider>
  );
};

export default Carousel;
